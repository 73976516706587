import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DeclarationsAuthService } from '../auth/declarations-auth.service';
import { getAsFormGroup } from '../shared/utils';
import {
  PersonalAccountDealingDeclaration,
  PersonalAccountDealingDeclarationToAdd
} from './personal-account-dealing-declaration.model';
import { PersonalAccountDealingDeclarationService } from './personal-account-dealing-declaration.service';
import { MatDialog } from '@angular/material/dialog';
import { PersonalAccountDealingDeclarationAckDialogComponent } from './personal-account-dealing-declaration-ack-dialog.component';

@Component({
  selector: 'ad-personal-account-dealing-declaration',
  templateUrl: './personal-account-dealing-declaration.component.html',
  styles: []
})
export class PersonalAccountDealingDeclarationComponent implements OnInit {
  public form: UntypedFormGroup;

  public declarationDate = new Date();
  public employee: string;
  public hasLastYearData = false;

  public alreadySubmitted = false;

  public getAsFormGroup = getAsFormGroup;

  constructor(
    public ackDialog: MatDialog,
    public authService: DeclarationsAuthService,
    private fb: UntypedFormBuilder,
    private service: PersonalAccountDealingDeclarationService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      additionalInformation: [null],
      hasPersonalDealingAccounts: [false, Validators.required],
      hasRelatedPersons: [false, Validators.required],
      personalDealingAccounts: this.fb.array([]),
      relatedPersons: this.fb.array([]),
      willNotDeal: [null, Validators.requiredTrue],
      willSeekApprovalToOpenDealingAccount: [null, Validators.requiredTrue],
      willSeekApprovalToTrade: [null, Validators.requiredTrue],
      willSendTradeConfirmationsAndStatements: [null, Validators.requiredTrue]
    });

    this.form.get('hasPersonalDealingAccounts').valueChanges.subscribe(() => this.handleHasPersonalDealingAccounts());
    this.form.get('hasRelatedPersons').valueChanges.subscribe(() => this.handleHasRelatedPerson());

    this.service.getMine$().subscribe((item) => {
      this.alreadySubmitted = !!item;

      if (this.alreadySubmitted) {
        this.populateInitialData(item, false);
        this.form.disable();
      } else {
        this.service.getMineFromLastYear$().subscribe((lastYearData) => this.populateInitialData(lastYearData, true));
      }
    });
  }

  private populateInitialData(data: PersonalAccountDealingDeclaration, isLastYearData: boolean): void {
    if (data) {
      this.hasLastYearData = isLastYearData;

      this.form.get('additionalInformation').setValue(data.additionalInformation);

      if (data.personalDealingAccounts && data.personalDealingAccounts.length > 0) {
        this.form.get('hasPersonalDealingAccounts').setValue(true);

        this.personalDealingAccounts.clear();

        for (const dealingAccount of data.personalDealingAccounts) {
          this.personalDealingAccounts.push(
            this.fb.group({
              accountNumber: [dealingAccount.accountNumber, Validators.required],
              brokerName: [dealingAccount.brokerName, Validators.required]
            })
          );
        }
      }

      if (data.relatedPersons && data.relatedPersons.length > 0) {
        this.form.get('hasRelatedPersons').setValue(true);

        this.relatedPersons.clear();

        for (const person of data.relatedPersons) {
          this.relatedPersons.push(
            this.fb.group({
              address: [person.address, Validators.required],
              dealingAccounts: this.fb.array(
                person.dealingAccounts.map((a) =>
                  this.fb.group({
                    accountNumber: [a.accountNumber, Validators.required],
                    brokerName: [a.brokerName, Validators.required]
                  })
                )
              ),
              name: [person.name, Validators.required],
              relationship: [person.relationship, Validators.required]
            })
          );
        }
      }

      if (!isLastYearData) {
        this.form.get('willNotDeal').setValue(data.willNotDeal);
        this.form.get('willSeekApprovalToOpenDealingAccount').setValue(data.willSeekApprovalToOpenDealingAccount);
        this.form.get('willSeekApprovalToTrade').setValue(data.willSeekApprovalToTrade);
        this.form.get('willSendTradeConfirmationsAndStatements').setValue(data.willSendTradeConfirmationsAndStatements);

        this.declarationDate = data.declarationDate;
        this.employee = data.employee;
      }
    }
  }

  public addPersonalDealingAccount(): void {
    this.personalDealingAccounts.push(
      this.fb.group({
        accountNumber: ['', Validators.required],
        brokerName: ['', Validators.required]
      })
    );
  }

  public removePersonalDealingAccount(index: number): void {
    this.personalDealingAccounts.removeAt(index);
  }

  public addRelatedPerson(): void {
    this.relatedPersons.push(
      this.fb.group({
        address: ['', Validators.required],
        dealingAccounts: this.fb.array([
          this.fb.group({
            accountNumber: ['', Validators.required],
            brokerName: ['', Validators.required]
          })
        ]),
        name: ['', Validators.required],
        relationship: ['', Validators.required]
      })
    );
  }

  public removeRelatedPerson(index: number): void {
    this.relatedPersons.removeAt(index);
  }

  public get personalDealingAccounts(): UntypedFormArray {
    return this.form.get('personalDealingAccounts') as UntypedFormArray;
  }

  public get relatedPersons(): UntypedFormArray {
    return this.form.get('relatedPersons') as UntypedFormArray;
  }

  public getRelatedPerson(index: number): UntypedFormGroup {
    return this.relatedPersons.at(index) as UntypedFormGroup;
  }

  public getRelatedPersonDealingAccounts(index: number): UntypedFormArray {
    return this.getRelatedPerson(index).get('dealingAccounts') as UntypedFormArray;
  }

  public addRelatedPersonDealingAccount(personIndex: number): void {
    this.getRelatedPersonDealingAccounts(personIndex).push(
      this.fb.group({
        accountNumber: ['', Validators.required],
        brokerName: ['', Validators.required]
      })
    );
  }

  public removeRelatedPersonDealingAccount(personIndex: number, index: number): void {
    this.getRelatedPersonDealingAccounts(personIndex).removeAt(index);
  }

  public get hasPersonalDealingAccounts(): boolean {
    return this.form && this.form.get('hasPersonalDealingAccounts').value;
  }

  public get hasPersonalDealingAccountsLabel(): string {
    return this.hasPersonalDealingAccounts
      ? 'I confirm I do hold Personal Dealing Accounts of which are as below:'
      : 'I confirm I do not hold any Personal Dealing Accounts.';
  }

  public get hasRelatedPersons(): boolean {
    return this.form && this.form.get('hasRelatedPersons').value;
  }

  public get hasRelatedPersonsLabel(): string {
    return this.hasRelatedPersons
      ? 'I confirm I have Related Persons holding Personal Dealing Accounts. Details of the Related Persons are as below:'
      : 'I confirm I do not have any Related Persons holding Personal Dealing Accounts.';
  }

  public submitForm(): void {
    const ackDialogRef = this.ackDialog.open(PersonalAccountDealingDeclarationAckDialogComponent, {
      data: {
        declarationDate: this.declarationDate
      },
      width: '800px'
    });

    ackDialogRef.afterClosed().subscribe((data) => {
      const value: PersonalAccountDealingDeclarationToAdd = {
        ...this.form.value,
        personalDealingAccounts: this.hasPersonalDealingAccounts ? this.personalDealingAccounts.value : null,
        relatedPersons: this.hasRelatedPersons ? this.relatedPersons.value : null,
        employee: data.name,
        declarationDate: data.declarationDate
      };

      this.form.reset();
      this.form.disable();

      this.service.acknowledge$(value).subscribe(() => this.router.navigate(['/']));
    });
  }

  private handleHasPersonalDealingAccounts(): void {
    if (this.hasPersonalDealingAccounts) {
      if (this.personalDealingAccounts.length === 0) {
        this.addPersonalDealingAccount();
      }
    } else {
      this.personalDealingAccounts.clear();
    }
  }

  private handleHasRelatedPerson(): void {
    if (this.hasRelatedPersons) {
      if (this.relatedPersons.length === 0) {
        this.addRelatedPerson();
      }
    } else {
      this.relatedPersons.clear();
    }
  }
}
