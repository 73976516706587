import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { interval, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HealthService {
  private apiEndpoint = `${environment.apiEndpoint}/health`;

  constructor(private http: HttpClient, private logger: NGXLogger) {}

  public isBackendAvailable$(): Observable<boolean> {
    return this.http
      .get(this.apiEndpoint, {
        responseType: 'text'
      })
      .pipe(
        map(() => true),
        catchError((error: any) => {
          if (error.status !== 503) {
            this.logger.error(error);
          }
          return of(false);
        })
      );
  }

  public isBackendAvailableInterval$(): Observable<boolean> {
    return interval(60000).pipe(switchMap(() => this.isBackendAvailable$()));
  }
}
