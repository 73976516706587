import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NGXLogger } from 'ngx-logger';
import { Observable, catchError, map, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PdfDeclarationRequest } from './pdf-declaration-request.model';
import { showErrorDialog, showSuccessSnackbar } from '../shared/utils';

@Injectable({
  providedIn: 'root'
})
export class PdfDeclarationsService {
  private apiEndpoint = `${environment.apiEndpoint}/api/pdf-declarations`;

  constructor(
    private http: HttpClient,
    private logger: NGXLogger,
    public apiErrorDialog: MatDialog,
    private snackBar: MatSnackBar
  ) {}

  generatePdfDeclaration$(request: PdfDeclarationRequest): Observable<void> {
    return this.http.post(this.apiEndpoint, request).pipe(
      map(() =>
        showSuccessSnackbar(this.snackBar, 'The PDF declaration was successfully generated and placed in SharePoint')
      ),
      catchError((error: any) => {
        this.logger.error(error);
        showErrorDialog(this.apiErrorDialog, error);
        return throwError(() => new Error(error));
      })
    );
  }

  public previewPdfDeclaration$(request: PdfDeclarationRequest): Observable<string> {
    return this.http
      .post(`${this.apiEndpoint}/preview`, request, {
        responseType: 'text'
      })
      .pipe(
        map((response: string) => `data:application/pdf;base64,${response.replace(/"/g, '')}`),
        catchError((error: any) => {
          this.logger.error(error);
          showErrorDialog(this.apiErrorDialog, error);
          return throwError(() => new Error(error));
        })
      );
  }

  public markDeclarationAsComplianceChecked$(
    declaration: PdfDeclarationRequest,
    checkedBy: string,
    checked: Date
  ): Observable<void> {
    return this.http
      .post(`${this.apiEndpoint}/mark-compliance-checked`, {
        declaration,
        checkedBy,
        checked
      })
      .pipe(
        map(() => showSuccessSnackbar(this.snackBar, 'Declaration marked as checked successfully')),
        catchError((error: any) => {
          this.logger.error(error);
          showErrorDialog(this.apiErrorDialog, error);
          return throwError(() => new Error(error));
        })
      );
  }
}
