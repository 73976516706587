import { Inject, Injectable } from '@angular/core';
import { MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { NGXLogger } from 'ngx-logger';
import { environment } from 'src/environments/environment';
import { AccountInfo, AuthenticationResult, RedirectRequest } from '@azure/msal-browser';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

interface IDToken {
  roles?: string[];
}

@Injectable({
  providedIn: 'root'
})
export class DeclarationsAuthService {
  public loggedIn$ = new BehaviorSubject<boolean>(false);

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private logger: NGXLogger
  ) {}

  public get account(): AccountInfo {
    return this.authService.instance.getActiveAccount();
  }

  public handleRedirectObservable$(): Observable<AuthenticationResult> {
    return this.authService.handleRedirectObservable();
  }

  public enableAccountStorageEvents(): void {
    this.authService.instance.enableAccountStorageEvents();
  }

  public checkAndSetActiveAccount(): boolean {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     */
    const activeAccount = this.authService.instance.getActiveAccount();

    if (activeAccount) {
      return true;
    } else if (this.authService.instance.getAllAccounts().length > 0) {
      const accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
      this.logger.log(this.account);
      return true;
    } else {
      return false;
    }
  }

  public setActiveAccount(account: AccountInfo): void {
    this.authService.instance.setActiveAccount(account);
  }

  public initializeMsalApp$(): Observable<void> {
    return this.authService.initialize();
  }

  public getAllAccounts(): AccountInfo[] {
    return this.authService.instance.getAllAccounts();
  }

  public login$(): Observable<void> {
    this.loggedIn$.next(false);

    if (this.msalGuardConfig.authRequest) {
      return this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
    } else {
      return this.authService.loginRedirect();
    }
  }
  public logout$(): Observable<void> {
    this.loggedIn$.next(false);

    return this.authService.logoutRedirect();
  }

  public hasRole(role: string): boolean {
    const typedIDToken = this.account?.idTokenClaims as IDToken;
    return (typedIDToken && typedIDToken.roles && typedIDToken.roles.filter((r) => r === role).length > 0) || false;
  }

  public hasOneOfRoles(roles: string[]): boolean {
    const typedIDToken = this.account.idTokenClaims as IDToken;

    if (!typedIDToken || !typedIDToken.roles) {
      return false;
    }

    for (const role of roles) {
      if (typedIDToken.roles.filter((r) => r === role).length > 0) {
        return true;
      }
    }

    return false;
  }

  public getAccessToken(): Observable<string> {
    return this.authService
      .acquireTokenSilent({
        scopes: environment.consentScopes
      })
      .pipe(map((authResult) => authResult.accessToken));
  }
}
