<table mat-table [dataSource]="dataSource" matSort class="bg-white">
  <!-- User Name -->
  <ng-container matColumnDef="userName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header style="cursor: pointer">User</th>
    <td mat-cell *matCellDef="let element" style="text-align: left">{{ element.userName }}</td>
  </ng-container>

  <!-- Compliance Manual -->
  <ng-container matColumnDef="ackedComplianceManual">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Compliance Manual</th>
    <td mat-cell *matCellDef="let element">
      <span
        *ngIf="element.ackedComplianceManual; else noBlock"
        [title]="element.complianceManualReadingTimeMs | dhumanize"
      >
        <mat-icon class="text-success">check</mat-icon>
      </span>
    </td></ng-container
  >

  <!-- AML Policy -->
  <ng-container matColumnDef="ackedAmlPolicy">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>AML Policy</th>
    <td mat-cell *matCellDef="let element">
      <span *ngIf="element.ackedAmlPolicy; else noBlock" [title]="element.amlPolicyReadingTimeMs | dhumanize">
        <mat-icon class="text-success">check</mat-icon>
      </span>
    </td></ng-container
  >

  <!-- DR/BCP Plan -->
  <ng-container matColumnDef="ackedDrBcpPlan">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>DR/BCP Plan</th>
    <td mat-cell *matCellDef="let element">
      <span *ngIf="element.ackedDrBcpPlan; else noBlock" [title]="element.drBcpPlanReadingTimeMs | dhumanize">
        <mat-icon class="text-success">check</mat-icon>
      </span>
    </td></ng-container
  >

  <!-- Employee Handbook -->
  <ng-container matColumnDef="ackedEmployeeHandbook">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Employee Handbook</th>
    <td mat-cell *matCellDef="let element">
      <span
        *ngIf="element.ackedEmployeeHandbook; else noBlock"
        [title]="element.employeeHandbookReadingTimeMs | dhumanize"
      >
        <mat-icon class="text-success">check</mat-icon>
      </span>
    </td></ng-container
  >

  <!-- Ethic Stds & Corp Culture -->
  <ng-container matColumnDef="ackedEthicStandardsAndCorporateCulture">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Ethic Stds &amp; Corp Culture</th>
    <td mat-cell *matCellDef="let element">
      <span
        *ngIf="element.ackedEthicStandardsAndCorporateCulture; else noBlock"
        [title]="element.ethicStandardAndCorporateCultureReadingTimeMs | dhumanize"
      >
        <mat-icon class="text-success">check</mat-icon>
      </span>
    </td></ng-container
  >

  <!-- Ext Business Interest Decl -->
  <ng-container matColumnDef="externalBusinessInterestDeclarationStatus">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Ext Business Interest Decl</th>
    <td mat-cell *matCellDef="let element">
      <mat-icon
        *ngIf="element.externalBusinessInterestDeclarationStatus === DeclarationStatus.NotCreated"
        class="text-danger"
        >close</mat-icon
      >
      <mat-icon
        *ngIf="element.externalBusinessInterestDeclarationStatus === DeclarationStatus.Created"
        class="text-warning"
        >info_outlined</mat-icon
      >
      @if (element.externalBusinessInterestDeclarationStatus === DeclarationStatus.Submitted) {
        <button
          class="btn btn-sm"
          (click)="
            showComplianceCheckDialog(
              element.externalBusinessInterestDeclarationId,
              DeclarationType.ExternalBusinessInterestDeclaration
            )
          "
        >
          <i class="bi bi-hourglass text-warning" title="Pending compliance check"></i>
        </button>
      } @else if (element.externalBusinessInterestDeclarationStatus === DeclarationStatus.ComplianceChecked) {
        <div class="d-flex justify-content-center align-items-center">
          <mat-icon class="text-success">check</mat-icon>
          <button
            class="btn btn-sm"
            (click)="
              requestToGeneratePdfDeclaration(
                element.externalBusinessInterestDeclarationId,
                DeclarationType.ExternalBusinessInterestDeclaration
              )
            "
          >
            <i class="bi bi-file-earmark-pdf" title="Regenerate PDF declaration"></i>
          </button>
          <button
            class="btn btn-sm btn-outline-danger border-0"
            (click)="
              onOpenResetDialog(
                element.externalBusinessInterestDeclarationId,
                DeclarationType.ExternalBusinessInterestDeclaration
              )
            "
          >
            <i class="bi bi-trash" title="Reset declaration"></i>
          </button>
        </div>
      }</td
  ></ng-container>

  <!-- Fit & Proper Decl -->
  <ng-container matColumnDef="fitAndProperDeclarationStatus">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Fit &amp; Proper Decl</th>
    <td mat-cell *matCellDef="let element">
      <mat-icon *ngIf="element.fitAndProperDeclarationStatus === DeclarationStatus.NotCreated" class="text-danger"
        >close</mat-icon
      >
      <mat-icon *ngIf="element.fitAndProperDeclarationStatus === DeclarationStatus.Created" class="text-warning"
        >info_outlined</mat-icon
      >
      @if (element.fitAndProperDeclarationStatus === DeclarationStatus.Submitted) {
        <button
          class="btn btn-sm"
          (click)="
            showComplianceCheckDialog(
              element.fitAndProperDeclarationId,
              DeclarationType.EmployeeFitAndProperDeclaration
            )
          "
        >
          <i class="bi bi-hourglass text-warning" title="Pending compliance check"></i>
        </button>
      } @else if (element.fitAndProperDeclarationStatus === DeclarationStatus.ComplianceChecked) {
        <div class="d-flex justify-content-center align-items-center">
          <mat-icon class="text-success">check</mat-icon>
          <button
            class="btn btn-sm"
            (click)="
              requestToGeneratePdfDeclaration(
                element.fitAndProperDeclarationId,
                DeclarationType.EmployeeFitAndProperDeclaration
              )
            "
          >
            <i class="bi bi-file-earmark-pdf" title="Regenerate PDF declaration"></i>
          </button>
          <button
            class="btn btn-sm btn-outline-danger border-0"
            (click)="
              onOpenResetDialog(element.fitAndProperDeclarationId, DeclarationType.EmployeeFitAndProperDeclaration)
            "
          >
            <i class="bi bi-trash" title="Reset declaration"></i>
          </button>
        </div>
      }</td
  ></ng-container>

  <!-- Pers Account Dealing Policy -->
  <ng-container matColumnDef="ackedPersonalAccountDealingPolicy">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Pers Account Dealing Policy</th>
    <td mat-cell *matCellDef="let element">
      <span
        *ngIf="element.ackedPersonalAccountDealingPolicy; else noBlock"
        [title]="element.personalAccountDealingPolicyReadingTimeMs | dhumanize"
      >
        <mat-icon class="text-success">check</mat-icon>
      </span>
    </td></ng-container
  >

  <!-- Pers Account Dealing Decl -->
  <ng-container matColumnDef="personalAccountDealingDeclarationStatus">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Pers Account Dealing Decl</th>
    <td mat-cell *matCellDef="let element">
      @if (element.personalAccountDealingDeclarationStatus === DeclarationStatus.NotCreated) {
        <mat-icon class="text-danger">close</mat-icon>
      } @else if (element.personalAccountDealingDeclarationStatus === DeclarationStatus.Created) {
        <mat-icon class="text-warning">info_outlined</mat-icon>
      } @else if (element.personalAccountDealingDeclarationStatus === DeclarationStatus.Submitted) {
        <button
          class="btn btn-sm"
          (click)="
            showComplianceCheckDialog(
              element.personalAccountDealingDeclarationId,
              DeclarationType.PersonalAccountDealingDeclaration
            )
          "
        >
          <i class="bi bi-hourglass text-warning" title="Pending compliance check"></i>
        </button>
      } @else if (element.personalAccountDealingDeclarationStatus === DeclarationStatus.ComplianceChecked) {
        <div class="d-flex justify-content-center align-items-center">
          <mat-icon class="text-success">check</mat-icon>
          <button
            class="btn btn-sm"
            (click)="
              requestToGeneratePdfDeclaration(
                element.personalAccountDealingDeclarationId,
                DeclarationType.PersonalAccountDealingDeclaration
              )
            "
          >
            <i class="bi bi-file-earmark-pdf" title="Regenerate PDF declaration"></i>
          </button>
          <button
            class="btn btn-sm btn-outline-danger border-0"
            (click)="
              onOpenResetDialog(
                element.personalAccountDealingDeclarationId,
                DeclarationType.PersonalAccountDealingDeclaration
              )
            "
          >
            <i class="bi bi-trash" title="Reset declaration"></i>
          </button>
        </div>
      }</td
  ></ng-container>

  <!-- Completion Date -->
  <ng-container matColumnDef="completionDate">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Completion Date</th>
    <td mat-cell *matCellDef="let element">
      {{ element.completionDate | date }}
    </td></ng-container
  >

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let element; columns: displayedColumns" class="element-row"></tr>

  <ng-template #noBlock>
    <mat-icon class="text-danger">close</mat-icon>
  </ng-template>
</table>
<mat-spinner *ngIf="loading"></mat-spinner>
