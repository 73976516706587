import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { PageChangeEventArgs, PdfViewerComponent } from '@syncfusion/ej2-angular-pdfviewer';
import {
  MagnificationService,
  ThumbnailViewService,
  ToolbarService,
  NavigationService
} from '@syncfusion/ej2-angular-pdfviewer';
import { MatDialog } from '@angular/material/dialog';
import { PdfViewerService } from './pdf-viewer.service';

@Component({
  selector: 'ad-custom-pdf-viewer',
  template: `
    <ejs-pdfviewer
      #pdfViewer
      [resourceUrl]="resourceUrl"
      style="height:800px; display: block"
      (pageChange)="pageChanged($event)"
      [enablePrint]="false"
      [enableDownload]="false"
      [toolbarSettings]="toolbarSettings"
    >
    </ejs-pdfviewer>
  `,
  providers: [MagnificationService, ThumbnailViewService, ToolbarService, NavigationService]
})
export class CustomPdfViewerComponent implements AfterViewInit {
  public resourceUrl: string;
  public toolbarSettings = { toolbarItems: ['PageNavigationTool', 'MagnificationTool'] };

  @Input()
  public fileName: string;

  @Output()
  public endOfDocumentReached: EventEmitter<number> = new EventEmitter();

  @ViewChild('pdfViewer')
  public pdfViewer: PdfViewerComponent;

  constructor(private pdfViewerService: PdfViewerService, public apiErrorDialog: MatDialog) {
    this.resourceUrl = `${window.location.origin}/assets/ej2-pdfviewer-lib`;
  }

  public ngAfterViewInit(): void {
    // Set an extra timeout to allow the assets/ej2-pdfviewer-lib to load
    setTimeout(() => {
      this.pdfViewerService
        .loadPdfDocument$(this.fileName)
        .subscribe((base64Content: string) => this.pdfViewer.load(base64Content, null));
    }, 1500);
  }

  public pageChanged(event: PageChangeEventArgs): void {
    if (this.pdfViewer && this.pdfViewer.pageCount === event.currentPageNumber) {
      this.endOfDocumentReached.emit(event.currentPageNumber);
    }
  }
}
