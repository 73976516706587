import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatMomentDateModule, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';

import { GridModule, GroupService } from '@syncfusion/ej2-angular-grids';
import {
  MagnificationService,
  NavigationService,
  PdfViewerModule,
  ThumbnailViewService,
  ToolbarService
} from '@syncfusion/ej2-angular-pdfviewer';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import {
  MsalBroadcastService,
  MsalGuard,
  MsalInterceptor,
  MsalModule,
  MsalRedirectComponent,
  MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG
} from '@azure/msal-angular';
import { environment } from 'src/environments/environment';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { AppUnavailableComponent } from './core/app-unavailable.component';
import { LandingComponent } from './home/landing.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './auth/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FitAndProperDeclarationComponent } from './fit-and-proper-declaration/fit-and-proper-declaration.component';
import { ExternalBusinessInterestDeclarationComponent } from './external-business-interest-declaration/external-business-interest-declaration.component';
import { PersonalAccountDealingDeclarationComponent } from './personal-account-dealing-declaration/personal-account-dealing-declaration.component';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { CustomPdfViewerComponent } from './shared/custom-pdf-viewer.component';
import { ApiErrorDialogComponent } from './core/api-error-dialog.component';
import { EmployeeHandbookComponent } from './employee-handbook/employee-handbook.component';
import { PersonalAccountDealingPolicyComponent } from './personal-account-dealing-policy/personal-account-dealing-policy.component';
import { EthicStandardsAndCorporateCultureComponent } from './ethic-standards-and-corporate-culture/ethic-standards-and-corporate-culture.component';
import { ComplianceDashboardComponent } from './hk/compliance-dashboard/compliance-dashboard.component';
import { DhumanizePipe } from './shared/dhumanize.pipe';
import { AuthorizedComponent } from './auth/authorized.component';
import { GvaUserComponent } from './auth/gva-user.component';
import { HkUserComponent } from './auth/hk-user.component';
import { GvaFitAndProperDeclarationComponent } from './geneva/gva-fit-and-proper-declaration/gva-fit-and-proper-declaration.component';
import { GVAAdminDashboardComponent } from './geneva/admin-dashboard/admin-dashboard.component';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { LoginFailedComponent } from './auth/login-failed.component';
import { MSALGuardConfigFactory, MSALInstanceFactory, MSALInterceptorConfigFactory } from './auth/msal.utils';
import { GvaFitAndProperDeclarationAdminDashboardComponent } from './geneva/gva-fit-and-proper-declaration/gva-fit-and-proper-declaration-admin-dashboard.component';
import { AlertDataPreloadedComponent } from './shared/alert-data-preloaded.component';
import { ComplianceManualComponent } from './compliance-manual/compliance-manual.component';
import { UkStatementOfResponsibilitiesComponent } from './uk/uk-statement-of-responsibilities/uk-statement-of-responsibilities.component';
import { UkUserComponent } from './auth/uk-user.component';
import { UkFitAndProperDeclarationComponent } from './uk/uk-fit-and-proper-declaration/uk-fit-and-proper-declaration.component';
import { UkSmcrComponent } from './uk/uk-smcr/uk-smcr.component';
import { UkAdminDashboardComponent } from './uk/uk-admin-dashboard/uk-admin-dashboard.component';
import { UkComplianceManualComponent } from './uk/uk-compliance-manual/uk-compliance-manual.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { FitAndProperDeclarationAckDialogComponent } from './fit-and-proper-declaration/fit-and-proper-declaration-ack-dialog.component';
import { BlockCopyPasteDirective } from './shared/block-copy-paste.directive';
import { NameMustMatchDirective } from './shared/name-must-match.directive';
import { ExternalBusinessInterestDeclarationAckDialogComponent } from './external-business-interest-declaration/external-business-interest-declaration-ack-dialog.component';
import { PersonalAccountDealingDeclarationAckDialogComponent } from './personal-account-dealing-declaration/personal-account-dealing-declaration-ack-dialog.component';
import { UkFitAndProperDeclarationAckDialogComponent } from './uk/uk-fit-and-proper-declaration/uk-fit-and-proper-declaration-ack-dialog.component';
import { UkSmcrAckDialogComponent } from './uk/uk-smcr/uk-smcr-ack-dialog.component';
import { UkStatementOfResponsibilitiesAckDialogComponent } from './uk/uk-statement-of-responsibilities/uk-statement-of-responsibilities-ack-dialog.component';
import { DeclarationComplianceCheckDialogComponent } from './pdf-declarations/declaration-compliance-check-dialog.component';
import { HkDrBcpAcksComponent } from './hk/dr-bcp-acks/dr-bcp-acks.component';
import { NgxIndexedDBModule } from 'ngx-indexed-db';

const MAT_MODULES = [
  MatButtonModule,
  MatCardModule,
  MatCheckboxModule,
  MatDatepickerModule,
  MatDialogModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatMomentDateModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatSelectModule,
  MatSidenavModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatSortModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule
];

const SYNCFUSION_MODULES = [GridModule, PdfViewerModule];
const SYNCFUSION_PROVIDERS = [
  GroupService,
  MagnificationService,
  ThumbnailViewService,
  ToolbarService,
  NavigationService
];

@NgModule({ declarations: [
        AppComponent,
        AppUnavailableComponent,
        LandingComponent,
        HomeComponent,
        LoginComponent,
        FitAndProperDeclarationComponent,
        FitAndProperDeclarationAckDialogComponent,
        ExternalBusinessInterestDeclarationComponent,
        ExternalBusinessInterestDeclarationAckDialogComponent,
        PersonalAccountDealingDeclarationComponent,
        PersonalAccountDealingDeclarationAckDialogComponent,
        CustomPdfViewerComponent,
        ApiErrorDialogComponent,
        EmployeeHandbookComponent,
        PersonalAccountDealingPolicyComponent,
        EthicStandardsAndCorporateCultureComponent,
        ComplianceDashboardComponent,
        DhumanizePipe,
        AuthorizedComponent,
        GvaUserComponent,
        HkDrBcpAcksComponent,
        HkUserComponent,
        GvaFitAndProperDeclarationComponent,
        GVAAdminDashboardComponent,
        AdminDashboardComponent,
        LoginFailedComponent,
        GvaFitAndProperDeclarationAdminDashboardComponent,
        AlertDataPreloadedComponent,
        ComplianceManualComponent,
        UkStatementOfResponsibilitiesComponent,
        UkStatementOfResponsibilitiesAckDialogComponent,
        UkUserComponent,
        UkFitAndProperDeclarationComponent,
        UkFitAndProperDeclarationAckDialogComponent,
        UkSmcrComponent,
        UkSmcrAckDialogComponent,
        UkAdminDashboardComponent,
        UkComplianceManualComponent,
        DeclarationComplianceCheckDialogComponent
    ],
    bootstrap: [AppComponent, MsalRedirectComponent], imports: [BlockCopyPasteDirective,
        NameMustMatchDirective,
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        ...MAT_MODULES,
        ...SYNCFUSION_MODULES,
        FormsModule,
        ReactiveFormsModule,
        MsalModule,
        RxReactiveFormsModule,
        LoggerModule.forRoot({
            level: environment.production ? NgxLoggerLevel.OFF : NgxLoggerLevel.LOG,
            serverLogLevel: NgxLoggerLevel.OFF,
            enableSourceMaps: !environment.production
        }),
        NgxIndexedDBModule.forRoot({
            name: 'AnnualDeclarationsDb',
            version: 1,
            objectStoresMeta: [
                {
                    store: 'pdf_documents',
                    storeConfig: { keyPath: 'id', autoIncrement: true },
                    storeSchema: [
                        { name: 'pdf_fileName', keypath: 'pdf_fileName', options: { unique: true } },
                        { name: 'pdf_base64', keypath: 'pdf_base64', options: { unique: false } }
                    ]
                }
            ]
        })], providers: [
        { provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true },
        { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
        {
            provide: MSAL_INSTANCE,
            useFactory: MSALInstanceFactory
        },
        {
            provide: MSAL_GUARD_CONFIG,
            useFactory: MSALGuardConfigFactory
        },
        {
            provide: MSAL_INTERCEPTOR_CONFIG,
            useFactory: MSALInterceptorConfigFactory
        },
        MsalService,
        MsalGuard,
        MsalBroadcastService,
        { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
        ...SYNCFUSION_PROVIDERS,
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule {}
