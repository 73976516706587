import { Component, OnInit } from '@angular/core';
import { ROLE_UK_USER } from '../shared/constants';
import { DeclarationsAuthService } from './declarations-auth.service';

@Component({
  selector: 'ad-uk-user',
  template: ` <span *ngIf="isAuthorized"> <ng-content></ng-content></span> `
})
export class UkUserComponent implements OnInit {
  public isAuthorized: boolean;

  constructor(private authService: DeclarationsAuthService) {}

  ngOnInit(): void {
    this.isAuthorized = this.authService.hasRole(ROLE_UK_USER);
  }
}
