import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NGXLogger } from 'ngx-logger';
import { Observable, catchError, map, of, switchMap, tap, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { showErrorDialog } from './utils';
import { NgxIndexedDBService } from 'ngx-indexed-db';

@Injectable({
  providedIn: 'root'
})
export class PdfViewerService {
  private apiEndpoint = `${environment.apiEndpoint}/api/static/pdf-viewer`;

  constructor(
    private http: HttpClient,
    private logger: NGXLogger,
    public apiErrorDialog: MatDialog,
    private dbService: NgxIndexedDBService
  ) {}

  public loadPdfDocument$(fileName: string): Observable<string> {
    return this.dbService.getByIndex('pdf_documents', 'pdf_fileName', fileName).pipe(
      switchMap((cached: { pdf_fileName: string; pdf_base64: string }) => {
        if (cached) {
          this.logger.info('Loading PDF from cache');
          return of(cached.pdf_base64);
        } else {
          this.logger.info('Loading PDF from API');
          return this.http
            .get(`${this.apiEndpoint}?fileName=${fileName}`, {
              responseType: 'text'
            })
            .pipe(
              map((response: string) => `data:application/pdf;base64,${response.replace(/"/g, '')}`),
              tap((base64Response: string) => {
                this.logger.info('Setting PDF in cache');
                this.dbService.add('pdf_documents', { pdf_fileName: fileName, pdf_base64: base64Response }).subscribe();
              }),
              catchError((error: any) => {
                this.logger.error(error);
                showErrorDialog(this.apiErrorDialog, error);
                return throwError(() => new Error(error));
              })
            );
        }
      })
    );
  }
}
