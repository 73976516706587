import { Component } from '@angular/core';

@Component({
    selector: 'ad-alert-data-preloaded',
    template: `
    <div class="alert alert-warning alert-dismissible fade show" role="alert">
      This declaration has been pre-loaded with the data you submitted last year. Please review any required change and
      click SUBMIT.
      <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
    </div>
  `,
    standalone: false
})
export class AlertDataPreloadedComponent {}
