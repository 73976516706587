import { Component, OnInit } from '@angular/core';
import { ROLE_GVA_ADMIN, ROLE_HK_ADMIN } from '../shared/constants';
import { DeclarationsAuthService } from './declarations-auth.service';

@Component({
    selector: 'ad-authorized',
    template: ` <span *ngIf="isAuthorized"><ng-content></ng-content></span> `,
    standalone: false
})
export class AuthorizedComponent implements OnInit {
  public isAuthorized: boolean;

  constructor(private authService: DeclarationsAuthService) {}

  ngOnInit(): void {
    this.isAuthorized = this.authService.hasRole(ROLE_GVA_ADMIN) || this.authService.hasRole(ROLE_HK_ADMIN);
  }
}
