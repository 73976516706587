import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { DeclarationsAuthService } from '../auth/declarations-auth.service';
import { UsersService } from '../core/users.service';
import { FitAndProperDeclaration, FitAndProperDeclarationToAdd } from './fit-and-proper-declaration.model';
import { FitAndProperDeclarationService } from './fit-and-proper-declaration.service';
import { MatDialog } from '@angular/material/dialog';
import { FitAndProperDeclarationAckDialogComponent } from './fit-and-proper-declaration-ack-dialog.component';

@Component({
    selector: 'ad-fit-and-proper-declaration',
    templateUrl: './fit-and-proper-declaration.component.html',
    styles: ['mat-radio-button { margin-left: 1rem; }', 'ol > li { margin-bottom: 1rem; }'],
    standalone: false
})
export class FitAndProperDeclarationComponent implements OnInit {
  public form: UntypedFormGroup;
  public declarationDate = new Date();
  public employee: string;
  public corporateTitle: string;
  public hasLastYearData = false;

  public alreadySubmitted = false;

  constructor(
    public ackDialog: MatDialog,
    public authService: DeclarationsAuthService,
    private fb: UntypedFormBuilder,
    private usersService: UsersService,
    private service: FitAndProperDeclarationService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      additionalInformation: [
        null,
        RxwebValidators.required({
          conditionalExpression: (x) =>
            x.section3Question1 === true ||
            x.section3Question2 === true ||
            x.section3Question3 === true ||
            x.section3Question4 === true ||
            x.section3Question5 === true ||
            x.section3Question6 === true ||
            x.section4Question1 === true ||
            x.section4Question2 === true ||
            x.section4Question3 === true ||
            x.section4Question4 === true ||
            x.section4Question5 === true ||
            x.section4Question6 === true ||
            x.section4Question7 === true ||
            x.section5Question1 === true
        })
      ],
      section2Question1: [null, Validators.required],
      section3Question1: [null, Validators.required],
      section3Question2: [null, Validators.required],
      section3Question3: [null, Validators.required],
      section3Question4: [null, Validators.required],
      section3Question5: [null, Validators.required],
      section3Question6: [null, Validators.required],
      section4Question1: [null, Validators.required],
      section4Question2: [null, Validators.required],
      section4Question3: [null, Validators.required],
      section4Question4: [null, Validators.required],
      section4Question5: [null, Validators.required],
      section4Question6: [null, Validators.required],
      section4Question7: [null, Validators.required],
      section5Question1: [null, Validators.required],
      sfcLicenseNumber: [null]
    });

    this.employee = this.authService.account.name;

    this.usersService.getCurrentUserCorporateTitle$().subscribe((title) => (this.corporateTitle = title));

    this.service.getMine$().subscribe((item) => {
      this.alreadySubmitted = !!item;

      if (this.alreadySubmitted) {
        this.populateInitialData(item, false);
        this.form.disable();
      } else {
        this.service.getMineFromLastYear$().subscribe((lastYearData) => this.populateInitialData(lastYearData, true));
      }
    });
  }

  public submitForm(): void {
    const ackDialogRef = this.ackDialog.open(FitAndProperDeclarationAckDialogComponent, {
      data: {
        declarationDate: this.declarationDate
      },
      width: '800px'
    });

    ackDialogRef.afterClosed().subscribe((data) => {
      const value: FitAndProperDeclarationToAdd = {
        ...this.form.value,
        corporateTitle: this.corporateTitle,
        employee: data.name,
        declarationDate: data.declarationDate
      };

      this.form.reset();
      this.form.disable();

      this.service.acknowledge$(value).subscribe(() => this.router.navigate(['/']));
    });
  }

  private populateInitialData(data: FitAndProperDeclaration, isLastYearData: boolean): void {
    if (data) {
      if (isLastYearData) {
        this.hasLastYearData = isLastYearData;
        this.form.get('sfcLicenseNumber').setValue(data.sfcLicenseNumber);
      } else {
        this.form.get('additionalInformation').setValue(data.additionalInformation);
        this.form.get('section2Question1').setValue(data.section2Question1);
        this.form.get('section3Question1').setValue(data.section3Question1);
        this.form.get('section3Question2').setValue(data.section3Question2);
        this.form.get('section3Question3').setValue(data.section3Question3);
        this.form.get('section3Question4').setValue(data.section3Question4);
        this.form.get('section3Question5').setValue(data.section3Question5);
        this.form.get('section3Question6').setValue(data.section3Question6);
        this.form.get('section4Question1').setValue(data.section4Question1);
        this.form.get('section4Question2').setValue(data.section4Question2);
        this.form.get('section4Question3').setValue(data.section4Question3);
        this.form.get('section4Question4').setValue(data.section4Question4);
        this.form.get('section4Question5').setValue(data.section4Question5);
        this.form.get('section4Question6').setValue(data.section4Question6);
        this.form.get('section4Question7').setValue(data.section4Question7);
        this.form.get('section5Question1').setValue(data.section5Question1);
        this.form.get('sfcLicenseNumber').setValue(data.sfcLicenseNumber);

        this.declarationDate = data.declarationDate;
        this.employee = data.employee;
      }
    }
  }
}
