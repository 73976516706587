import { Component } from '@angular/core';

@Component({
  selector: 'ad-app-unavailable',
  template: `
    <div class="d-flex flex-column justify-content-around align-items-center">
      <h1>Service Unavailable</h1>
      <h4>The application is currently unavailable. Please try again later or contact IT if the problem persists.</h4>
    </div>
  `
})
export class AppUnavailableComponent {}
