import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { ComplianceDashboardItem } from './compliance-dashboard.model';
import { ComplianceDashboardService } from './compliance-dashboard.service';
import { PdfDeclarationsService } from 'src/app/pdf-declarations/pdf-declarations.service';
import { DeclarationType } from 'src/app/shared/declaration-type.enum';
import { OfficeLocation } from 'src/app/shared/office-location.enum';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { DeclarationComplianceCheckDialogComponent } from 'src/app/pdf-declarations/declaration-compliance-check-dialog.component';
import { DeclarationStatus } from 'src/app/shared/declaration-status.enum';
import { PdfDeclarationRequest } from 'src/app/pdf-declarations/pdf-declaration-request.model';
import { Dialog, DialogUtility } from '@syncfusion/ej2-popups';
import { FitAndProperDeclarationService } from 'src/app/fit-and-proper-declaration/fit-and-proper-declaration.service';
import { Observable } from 'rxjs';
import { PersonalAccountDealingDeclarationService } from 'src/app/personal-account-dealing-declaration/personal-account-dealing-declaration.service';
import { ExternalBusinessInterestDeclarationService } from 'src/app/external-business-interest-declaration/external-business-interest-declaration.service';

@Component({
  selector: 'ad-compliance-dashboard',
  templateUrl: './compliance-dashboard.component.html',
  styleUrls: ['./compliance-dashboard.component.css'],
  standalone: false
})
export class ComplianceDashboardComponent implements OnInit {
  public displayedColumns: string[] = [
    'userName',
    'ackedComplianceManual',
    'ackedAmlPolicy',
    'ackedDrBcpPlan',
    'ackedEmployeeHandbook',
    'ackedEthicStandardsAndCorporateCulture',
    'externalBusinessInterestDeclarationStatus',
    'fitAndProperDeclarationStatus',
    'ackedPersonalAccountDealingPolicy',
    'personalAccountDealingDeclarationStatus',
    'completionDate'
  ];
  public dataSource: MatTableDataSource<ComplianceDashboardItem>;

  public DeclarationType = DeclarationType;
  public DeclarationStatus = DeclarationStatus;
  public loading = true;
  private resetDialog: Dialog;

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private complianceDashboardService: ComplianceDashboardService,
    private pdfDeclarationsService: PdfDeclarationsService,
    public checkDialog: MatDialog,
    private fitAndProperDeclarationService: FitAndProperDeclarationService,
    private personalAccountDealingDeclarationService: PersonalAccountDealingDeclarationService,
    private externalBusinessInterestDeclarationService: ExternalBusinessInterestDeclarationService
  ) {}

  ngOnInit(): void {
    this.loadComplianceDashboardItems();
  }

  public requestToGeneratePdfDeclaration(declarationId: number, declarationType: DeclarationType): void {
    this.pdfDeclarationsService
      .generatePdfDeclaration$({
        declarationType,
        declarationId,
        officeLocation: OfficeLocation.HongKong
      })
      .subscribe();
  }

  public showComplianceCheckDialog(declarationId: number, declarationType: DeclarationType) {
    const request: PdfDeclarationRequest = {
      declarationId,
      declarationType,
      officeLocation: OfficeLocation.HongKong
    };

    const ackDialogRef = this.checkDialog.open(DeclarationComplianceCheckDialogComponent, {
      data: request,
      minWidth: '1000px'
    });

    ackDialogRef.afterClosed().subscribe((data) => {
      if (data.rejected) {
        this.deleteDeclaration(declarationType, declarationId);
      } else {
        this.pdfDeclarationsService
          .markDeclarationAsComplianceChecked$(request, data.name, data.date)
          .subscribe(() => this.loadComplianceDashboardItems());
      }
    });
  }

  public onOpenResetDialog = (declarationId: number, declarationType: DeclarationType): void => {
    this.resetDialog = DialogUtility.confirm({
      closeOnEscape: true,
      content: 'Are you sure you want to reset this declaration?',
      okButton: { click: this.resetDeclaration.bind(this, declarationId, declarationType) },
      position: { X: 'center', Y: 'center' },
      showCloseIcon: true,
      title: 'RESET DECLARATION'
    });
  };

  private loadComplianceDashboardItems(): void {
    this.complianceDashboardService.listComplianceDashboardItems$().subscribe((items) => {
      this.dataSource = new MatTableDataSource(items);
      this.dataSource.sort = this.sort;
      this.loading = false;
    });
  }

  private resetDeclaration(declarationId: number, declarationType: DeclarationType): void {
    this.resetDialog?.hide();
    this.deleteDeclaration(declarationType, declarationId);
  }

  private deleteDeclaration(declarationType: DeclarationType, declarationId: number) {
    let obs$: Observable<void> | null = null;

    switch (declarationType) {
      case DeclarationType.EmployeeFitAndProperDeclaration:
        obs$ = this.fitAndProperDeclarationService.delete$(declarationId);
        break;
      case DeclarationType.PersonalAccountDealingDeclaration:
        obs$ = this.personalAccountDealingDeclarationService.delete$(declarationId);
        break;
      case DeclarationType.ExternalBusinessInterestDeclaration:
        obs$ = this.externalBusinessInterestDeclarationService.delete$(declarationId);
        break;
      default:
        break;
    }

    if (obs$) {
      obs$.subscribe(() => this.loadComplianceDashboardItems());
    }
  }
}
