import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { showErrorDialog, showSuccessSnackbar } from '../shared/utils';
import { FitAndProperDeclaration, FitAndProperDeclarationToAdd } from './fit-and-proper-declaration.model';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class FitAndProperDeclarationService {
  private apiEndpoint = `${environment.apiEndpoint}/api/employee-fit-and-proper-declaration`;

  constructor(
    private http: HttpClient,
    private logger: NGXLogger,
    public apiErrorDialog: MatDialog,
    private snackBar: MatSnackBar
  ) {}

  public getMine$(): Observable<FitAndProperDeclaration | null> {
    return this.http.get<FitAndProperDeclaration>(`${this.apiEndpoint}/me`).pipe(catchError(() => of(null)));
  }

  public getMineFromLastYear$(): Observable<FitAndProperDeclaration | null> {
    return this.http.get<FitAndProperDeclaration>(`${this.apiEndpoint}/me/last-year`).pipe(catchError(() => of(null)));
  }

  acknowledge$(value: FitAndProperDeclarationToAdd): Observable<void> {
    return this.http.post(this.apiEndpoint, value).pipe(
      map(() => showSuccessSnackbar(this.snackBar, 'Your declaration was successfully recorded')),
      catchError((error: any) => {
        this.logger.error(error);
        showErrorDialog(this.apiErrorDialog, error);
        return throwError(() => new Error(error));
      })
    );
  }

  public markAsComplianceChecked$(id: number, checkedBy: string, checked: Date): Observable<void> {
    return this.http
      .patch(`${this.apiEndpoint}/${id}/mark-compliance-checked`, {
        checkedBy,
        checked
      })
      .pipe(
        map(() => showSuccessSnackbar(this.snackBar, 'Declaration marked as checked successfully')),
        catchError((error: any) => {
          this.logger.error(error);
          showErrorDialog(this.apiErrorDialog, error);
          return throwError(() => new Error(error));
        })
      );
  }
}
