import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { BasicUser } from 'src/app/core/basic-user.model';
import { DeclarationsAuthService } from '../../auth/declarations-auth.service';
import { UsersService } from '../../core/users.service';
import { GVAFitAndProperDeclaration, GVAFitAndProperDeclarationToAdd } from './gva-fit-and-proper-declaration.model';
import { GVAFitAndProperDeclarationService } from './gva-fit-and-proper-declaration.service';
import { Location } from '@angular/common';

@Component({
  selector: 'ad-gva-fit-and-proper-declaration',
  templateUrl: './gva-fit-and-proper-declaration.component.html',
  styles: ['mat-radio-button { margin-left: 1rem; }']
})
export class GvaFitAndProperDeclarationComponent implements OnInit {
  public form: UntypedFormGroup;
  public declarationDate = new Date();
  public corporateTitle: string;
  public hasLastYearData = false;

  public alreadySubmitted = false;
  public user: BasicUser;
  public isView = false;

  constructor(
    public authService: DeclarationsAuthService,
    private fb: UntypedFormBuilder,
    private usersService: UsersService,
    private service: GVAFitAndProperDeclarationService,
    private router: Router,
    private route: ActivatedRoute,
    public location: Location
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      additionalInformation: [
        null,
        RxwebValidators.required({
          conditionalExpression: (x) =>
            x.section3Question1 === true ||
            x.section3Question2 === true ||
            x.section3Question3 === true ||
            x.section3Question4 === true ||
            x.section3Question5 === true ||
            x.section3Question6 === true ||
            x.section3Question7 === true ||
            x.section3Question8 === true ||
            x.section31Question1 === true ||
            x.section31Question2 === true ||
            x.section31Question3 === true ||
            x.section31Question4 === true ||
            x.section31Question5 === true ||
            x.section4Question1 === true ||
            x.section4Question2 === true ||
            x.section4Question3 === true ||
            x.section4Question4 === true ||
            x.section4Question5 === true ||
            x.section5Question1 === true
        })
      ],
      section2Question1: [null, Validators.requiredTrue],
      section3Question1: [null, Validators.required],
      section3Question2: [null, Validators.required],
      section3Question3: [null, Validators.required],
      section3Question4: [null, Validators.required],
      section3Question5: [null, Validators.required],
      section3Question6: [null, Validators.required],
      section3Question7: [null, Validators.required],
      section3Question8: [null, Validators.required],
      section31Question1: [null, Validators.required],
      section31Question2: [null, Validators.required],
      section31Question3: [null, Validators.required],
      section31Question4: [null, Validators.required],
      section31Question5: [null, Validators.required],
      section4Question1: [null, Validators.required],
      section4Question2: [null, Validators.required],
      section4Question3: [null, Validators.required],
      section4Question4: [null, Validators.required],
      section4Question5: [null, Validators.required],
      section5Question1: [null, Validators.required],
      soFitNumber: [null]
    });

    this.usersService.getCurrentUserCorporateTitle$().subscribe((title) => (this.corporateTitle = title));

    this.route.paramMap.subscribe((params) => {
      const email = params.get('email');
      const year = Number.parseInt(params.get('year'), 10);
      const month = Number.parseInt(params.get('month'), 10);

      if (email && year && month) {
        // View someone else's
        this.form.disable();
        this.isView = true;

        this.service.getOne$(email, year, month).subscribe((data) => {
          if (data) {
            this.populateInitialData(data);
          } else {
            this.usersService.getOne$(email).subscribe((user) => (this.user = user));
          }
        });
      } else {
        this.service.getMine$().subscribe((data) => {
          if (data) {
            this.populateInitialData(data);
          }
          this.declarationDate = this.declarationDate || new Date();

          if (!this.alreadySubmitted && !this.form.get('soFitNumber')?.value) {
            this.service.getMineFromPreviousPeriod$().subscribe((previousPeriodData) => {
              if (previousPeriodData) {
                this.hasLastYearData = true;
                this.form.get('soFitNumber').setValue(previousPeriodData.soFitNumber);
              }
            });
          }
        });
        this.usersService.getOne$(this.authService.account.username).subscribe((user) => (this.user = user));
      }
    });
  }

  public submitForm(): void {
    if (this.form.valid && this.form.get('additionalInformation').valid) {
      const value: GVAFitAndProperDeclarationToAdd = {
        ...this.form.value,
        corporateTitle: this.corporateTitle,
        employee: this.authService.account.name,
        declarationDate: this.declarationDate
      };

      this.form.reset();
      this.form.disable();

      this.service.acknowledge$(value).subscribe(() => this.router.navigate(['/']));
    }
  }

  private populateInitialData(data: GVAFitAndProperDeclaration): void {
    if (data) {
      this.form.get('additionalInformation').setValue(data.additionalInformation);
      this.form.get('section2Question1').setValue(data.section2Question1);
      this.form.get('section3Question1').setValue(data.section3Question1);
      this.form.get('section3Question2').setValue(data.section3Question2);
      this.form.get('section3Question3').setValue(data.section3Question3);
      this.form.get('section3Question4').setValue(data.section3Question4);
      this.form.get('section3Question5').setValue(data.section3Question5);
      this.form.get('section3Question6').setValue(data.section3Question6);
      this.form.get('section3Question7').setValue(data.section3Question7);
      this.form.get('section3Question8').setValue(data.section3Question8);
      this.form.get('section31Question1').setValue(data.section31Question1);
      this.form.get('section31Question2').setValue(data.section31Question2);
      this.form.get('section31Question3').setValue(data.section31Question3);
      this.form.get('section31Question4').setValue(data.section31Question4);
      this.form.get('section31Question5').setValue(data.section31Question5);
      this.form.get('section4Question1').setValue(data.section4Question1);
      this.form.get('section4Question2').setValue(data.section4Question2);
      this.form.get('section4Question3').setValue(data.section4Question3);
      this.form.get('section4Question4').setValue(data.section4Question4);
      this.form.get('section4Question5').setValue(data.section4Question5);
      this.form.get('section5Question1').setValue(data.section5Question1);
      this.form.get('soFitNumber').setValue(data.soFitNumber);

      this.declarationDate = data.declarationDate;
      this.user = {
        name: data.employee,
        email: data.userEmail,
        title: data.corporateTitle
      };

      if (data.declarationDate) {
        this.alreadySubmitted = true;
        this.form.disable();
      }
    }
  }
}
