import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { DeclarationsAuthService } from 'src/app/auth/declarations-auth.service';
import { BasicUser } from 'src/app/core/basic-user.model';
import { UsersService } from 'src/app/core/users.service';
import { UkFitAndProperDeclaration, UkFitAndProperDeclarationToAdd } from './uk-fit-and-proper-declaration.model';
import { UkFitAndProperDeclarationService } from './uk-fit-and-proper-declaration.service';
import { MatDialog } from '@angular/material/dialog';
import { UkFitAndProperDeclarationAckDialogComponent } from './uk-fit-and-proper-declaration-ack-dialog.component';

@Component({
    selector: 'ad-uk-fit-and-proper-declaration',
    templateUrl: './uk-fit-and-proper-declaration.component.html',
    styleUrls: ['./uk-fit-and-proper-declaration.component.css'],
    standalone: false
})
export class UkFitAndProperDeclarationComponent implements OnInit {
  public form: UntypedFormGroup;
  public declarationDate: Date;
  public user: BasicUser;

  public onBehalfOf = false;

  public alreadySubmitted = false;

  constructor(
    public ackDialog: MatDialog,
    private authService: DeclarationsAuthService,
    private fb: UntypedFormBuilder,
    private usersService: UsersService,
    private service: UkFitAndProperDeclarationService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      additionalInformation: [
        null,
        RxwebValidators.required({
          conditionalExpression: (x) =>
            x.section3Question1 === true ||
            x.section3Question2 === true ||
            x.section3Question3 === true ||
            x.section3Question4 === true ||
            x.section3Question5 === true ||
            x.section3Question6 === true ||
            x.section3Question7 === true ||
            x.section4Question1 === true ||
            x.section4Question2 === true ||
            x.section4Question3 === true ||
            x.section4Question4 === true ||
            x.section4Question5 === true ||
            x.section4Question6 === true ||
            x.section4Question7 === true ||
            x.section5Question1 === true
        })
      ],
      irn: [null],
      section2Question1: [null, Validators.requiredTrue],
      section3Question1: [null, Validators.required],
      section3Question2: [null, Validators.required],
      section3Question3: [null, Validators.required],
      section3Question4: [null, Validators.required],
      section3Question5: [null, Validators.required],
      section3Question6: [null, Validators.required],
      section3Question7: [null, Validators.required],
      section4Question1: [null, Validators.required],
      section4Question2: [null, Validators.required],
      section4Question3: [null, Validators.required],
      section4Question4: [null, Validators.required],
      section4Question5: [null, Validators.required],
      section4Question6: [null, Validators.required],
      section4Question7: [null, Validators.required],
      section5Question1: [null, Validators.required]
    });

    this.route.paramMap.subscribe((params) => {
      const email = params.get('email');

      if (email) {
        this.onBehalfOf = email !== this.authService.account.username;
        this.service.getOne$(email).subscribe((data) => {
          if (data) {
            this.populateInitialData(data);
          } else {
            this.usersService.getOne$(email).subscribe((user) => (this.user = user));
          }
        });
      } else {
        this.service.getMine$().subscribe((data) => {
          if (data) {
            this.populateInitialData(data);
          }
          this.declarationDate = this.declarationDate || new Date();
        });
        this.usersService.getOne$(this.authService.account.username).subscribe((user) => (this.user = user));
      }
    });
  }

  public saveOrSubmitForm(): void {
    if (this.onBehalfOf) {
      this.saveFormOnBehalfOf();
    } else {
      this.submitFormAsTheUser();
    }
  }

  private submitFormAsTheUser(): void {
    const ackDialogRef = this.ackDialog.open(UkFitAndProperDeclarationAckDialogComponent, {
      data: {
        declarationDate: this.declarationDate
      },
      width: '800px'
    });

    ackDialogRef.afterClosed().subscribe((data) => {
      const value: UkFitAndProperDeclarationToAdd = {
        ...this.form.value,
        corporateTitle: this.user.title,
        employee: data.name,
        declarationDate: data.declarationDate,
        userEmail: this.user.email
      };

      this.form.reset();
      this.form.disable();

      this.service.acknowledge$(value).subscribe(() => this.router.navigate(['/']));
    });
  }

  private saveFormOnBehalfOf(): void {
    const value: UkFitAndProperDeclarationToAdd = {
      ...this.form.value,
      corporateTitle: this.user.title,
      employee: this.user.name,
      declarationDate: this.declarationDate,
      userEmail: this.user.email
    };

    this.form.reset();
    this.form.disable();

    this.service.acknowledge$(value).subscribe(() => this.router.navigate(['/']));
  }

  private populateInitialData(data: UkFitAndProperDeclaration): void {
    if (data) {
      this.form.get('additionalInformation').setValue(data.additionalInformation);
      this.form.get('irn').setValue(data.irn);
      this.form.get('section2Question1').setValue(data.section2Question1);
      this.form.get('section3Question1').setValue(data.section3Question1);
      this.form.get('section3Question2').setValue(data.section3Question2);
      this.form.get('section3Question3').setValue(data.section3Question3);
      this.form.get('section3Question4').setValue(data.section3Question4);
      this.form.get('section3Question5').setValue(data.section3Question5);
      this.form.get('section3Question6').setValue(data.section3Question6);
      this.form.get('section3Question7').setValue(data.section3Question7);
      this.form.get('section4Question1').setValue(data.section4Question1);
      this.form.get('section4Question2').setValue(data.section4Question2);
      this.form.get('section4Question3').setValue(data.section4Question3);
      this.form.get('section4Question4').setValue(data.section4Question4);
      this.form.get('section4Question5').setValue(data.section4Question5);
      this.form.get('section4Question6').setValue(data.section4Question6);
      this.form.get('section4Question7').setValue(data.section4Question7);
      this.form.get('section5Question1').setValue(data.section5Question1);

      this.declarationDate = data.declarationDate;
      this.user = {
        name: data.employee,
        email: data.userEmail,
        title: data.corporateTitle
      };

      if (data.declarationDate) {
        this.alreadySubmitted = true;
        this.form.disable();
      }
    }
  }
}
