import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MsalBroadcastService } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { filter } from 'rxjs/operators';
import { DeclarationsAuthService } from '../auth/declarations-auth.service';

@Component({
  selector: 'ad-landing',
  template: `
    <div class="d-flex gap-3 align-items-center">
      <h3>Welcome</h3>
      <button mat-stroked-button (click)="login()">PLEASE LOG IN</button>
    </div>
  `,
  styles: ['div { margin: 2rem }', 'h3 { margin-bottom: 0 }']
})
export class LandingComponent implements OnInit {
  constructor(
    private declarationsAuthService: DeclarationsAuthService,
    private router: Router,
    private msalBroadcastService: MsalBroadcastService
  ) {}

  public ngOnInit(): void {
    if (this.declarationsAuthService.loggedIn) {
      this.router.navigate(['/home']);
    }

    this.msalBroadcastService.msalSubject$
      .pipe(filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS))
      .subscribe((result: EventMessage) => {
        const payload = result.payload as AuthenticationResult;
        this.declarationsAuthService.setActiveAccount(payload.account);
        this.router.navigate(['/home']);
      });

    this.msalBroadcastService.inProgress$
      .pipe(filter((status: InteractionStatus) => status === InteractionStatus.None))
      .subscribe(() => {
        if (this.declarationsAuthService.loggedIn) {
          this.router.navigate(['/home']);
        }
      });
  }

  public login(): void {
    this.declarationsAuthService.login$().subscribe();
  }
}
