import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { DeclarationsAuthService } from '../auth/declarations-auth.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PdfDeclarationsService } from './pdf-declarations.service';
import { PdfDeclarationRequest } from './pdf-declaration-request.model';

@Component({
    selector: 'ad-declaration-compliance-check-dialog',
    template: `
    <h1 mat-dialog-title>CHECK DECLARATION</h1>
    <div mat-dialog-content>
      <ejs-pdfviewer id="pdfViewer" style="height: 800px; display: block" [resourceUrl]="resourceUrl"> </ejs-pdfviewer>
    </div>
    <div mat-dialog-actions>
      <button mat-button (click)="closeDialog()">MARK AS CHECKED</button>
    </div>
  `,
    standalone: false
})
export class DeclarationComplianceCheckDialogComponent implements AfterViewInit {
  public resourceUrl: string;

  constructor(
    public authService: DeclarationsAuthService,
    private declarationsService: PdfDeclarationsService,
    public dialogRef: MatDialogRef<DeclarationComplianceCheckDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PdfDeclarationRequest
  ) {
    this.resourceUrl = `${window.location.origin}/assets/ej2-pdfviewer-lib`;
  }

  ngAfterViewInit(): void {
    // Set an extra timeout to allow the assets/ej2-pdfviewer-lib to load
    setTimeout(() => {
      this.declarationsService.previewPdfDeclaration$(this.data).subscribe((response: string) => {
        const viewer = (<any>document.getElementById('pdfViewer')).ej2_instances[0];
        viewer.load(response, null);
      });
    }, 1500);
  }

  public closeDialog() {
    this.dialogRef.close({
      name: this.authService.account.name,
      date: new Date()
    });
  }
}
