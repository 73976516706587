import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { filter, map, shareReplay, takeUntil } from 'rxjs/operators';
import { NGXLogger } from 'ngx-logger';
import { DeclarationsAuthService } from './auth/declarations-auth.service';
import { TitleService } from './core/title.service';
import { MsalBroadcastService } from '@azure/msal-angular';
import { HealthService } from './core/health.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';

@Component({
  selector: 'ad-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  standalone: false
})
export class AppComponent implements OnInit, OnDestroy {
  private readonly destroying$ = new Subject<void>();

  public isIframe: boolean;
  public userManual = environment.userManual;
  public feedbackForm = environment.feedbackForm;
  public production = environment.production;
  private wasUnavailable = false;

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
    map((result) => result.matches),
    shareReplay()
  );

  constructor(
    private breakpointObserver: BreakpointObserver,
    private logger: NGXLogger,
    private declarationsAuthService: DeclarationsAuthService,
    private titleService: TitleService,
    private msalBroadcastService: MsalBroadcastService,
    private healthService: HealthService,
    private router: Router
  ) {
    //  This is to avoid reload during acquireTokenSilent() because of hidden iframe
    this.isIframe = window !== window.parent && !window.opener;
  }

  public get title(): string {
    const pageTitle = this.titleService.title;

    if (pageTitle && pageTitle.length > 0) {
      return `CIGP Declarations - ${pageTitle}`;
    } else {
      return `CIGP Declarations`;
    }
  }

  public login(): void {
    this.declarationsAuthService.login$().subscribe();
  }

  public ngOnInit(): void {
    // This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window
    this.declarationsAuthService.enableAccountStorageEvents();

    this.declarationsAuthService.handleRedirectObservable$().subscribe();

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.ACCOUNT_ADDED || msg.eventType === EventType.ACCOUNT_REMOVED
        )
      )
      .subscribe(() => {
        if (this.declarationsAuthService.getAllAccounts().length === 0) {
          window.location.pathname = '/';
        }
      });

    this.msalBroadcastService.msalSubject$
      .pipe(filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS))
      .subscribe((result: EventMessage) => {
        const payload = result.payload as AuthenticationResult;
        this.declarationsAuthService.setActiveAccount(payload.account);

        this.logger.log('Logged in');

        this.declarationsAuthService.loggedIn$.next(true);
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this.destroying$)
      )
      .subscribe(() => {
        if (!this.declarationsAuthService.checkAndSetActiveAccount()) {
          this.declarationsAuthService.login$().subscribe();
        } else {
          this.declarationsAuthService.loggedIn$.next(true);
        }
      });

    this.healthService.isBackendAvailableInterval$().subscribe((isAvailable) => {
      if (!isAvailable) {
        this.wasUnavailable = true;
        this.router.navigate(['/unavailable']);
      } else if (this.wasUnavailable) {
        this.wasUnavailable = false;
        this.router.navigate(['/']);
      }
    });
  }

  public ngOnDestroy(): void {
    this.destroying$.next(undefined);
    this.destroying$.complete();
  }
}
