import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { DeclarationsAuthService } from '../auth/declarations-auth.service';
import { getAsFormGroup } from '../shared/utils';
import {
  ExternalBusinessInterestDeclaration,
  ExternalBusinessInterestDeclarationToAdd
} from './external-business-interest-declaration.model';
import { ExternalBusinessInterestDeclarationService } from './external-business-interest-declaration.service';
import { MatDialog } from '@angular/material/dialog';
import { ExternalBusinessInterestDeclarationAckDialogComponent } from './external-business-interest-declaration-ack-dialog.component';

@Component({
    selector: 'ad-external-business-interest-declaration',
    templateUrl: './external-business-interest-declaration.component.html',
    standalone: false
})
export class ExternalBusinessInterestDeclarationComponent implements OnInit {
  public form: UntypedFormGroup;

  public other = 'Other';
  public predefinedRoles = ['Controlling Power', 'Director', 'Owner', 'Partner', 'Employee'];
  public roles = this.predefinedRoles.concat([this.other]);
  public declarationDate = new Date();
  public employee: string;
  public hasLastYearData = false;

  public alreadySubmitted = false;

  public getAsFormGroup = getAsFormGroup;

  constructor(
    public ackDialog: MatDialog,
    public authService: DeclarationsAuthService,
    private fb: UntypedFormBuilder,
    private service: ExternalBusinessInterestDeclarationService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      additionalInformation: [null],
      businessInterests: this.fb.array([]),
      hasExternalBusinessInterest: [false, Validators.required]
    });

    this.form.get('hasExternalBusinessInterest').valueChanges.subscribe(() => this.handleHasExternalBusinessInterest());

    this.service.getMine$().subscribe((item) => {
      this.alreadySubmitted = !!item;

      if (this.alreadySubmitted) {
        this.populateInitialData(item, false);
        this.form.disable();
      } else {
        this.service.getMineFromLastYear$().subscribe((lastYearData) => this.populateInitialData(lastYearData, true));
      }
    });
  }

  private populateInitialData(data: ExternalBusinessInterestDeclaration, isLastYearData: boolean): void {
    if (data) {
      this.hasLastYearData = isLastYearData;

      this.form.get('additionalInformation').setValue(data.additionalInformation);

      if (data.businessInterests && data.businessInterests.length > 0) {
        this.form.get('hasExternalBusinessInterest').setValue(true);

        this.businessInterests.clear();
        for (const businessInterest of data.businessInterests) {
          this.businessInterests.push(
            this.fb.group({
              name: [businessInterest.name, Validators.required],
              role: [
                this.roles.includes(businessInterest.role) ? businessInterest.role : this.other,
                Validators.required
              ],
              roleOther: [
                this.roles.includes(businessInterest.role) ? '' : businessInterest.role,
                RxwebValidators.required({ conditionalExpression: (x, y) => x.role === this.other })
              ],
              dateOfCommencement: [businessInterest.dateOfCommencement, Validators.required]
            })
          );
        }
      }

      if (!isLastYearData) {
        this.declarationDate = data.declarationDate;
      }

      this.employee = data.employee;
    }
  }

  public handleHasExternalBusinessInterest(): void {
    if (this.hasExternalBusinessInterest) {
      if (this.businessInterests.length === 0) {
        this.addBusinessInterest();
      }
    } else {
      this.businessInterests.clear();
    }
  }

  public addBusinessInterest(): void {
    this.businessInterests.push(
      this.fb.group({
        name: ['', Validators.required],
        role: ['', Validators.required],
        roleOther: ['', RxwebValidators.required({ conditionalExpression: (x, y) => x.role === this.other })],
        dateOfCommencement: [null, Validators.required]
      })
    );
  }

  public removeBusinessInterest(index: number): void {
    this.businessInterests.removeAt(index);
  }

  public get businessInterests(): UntypedFormArray {
    return this.form.get('businessInterests') as UntypedFormArray;
  }

  public get hasExternalBusinessInterest(): boolean {
    return !!this.form.get('hasExternalBusinessInterest').value;
  }

  public get hasExternalBusinessInterestLabel(): string {
    return this.hasExternalBusinessInterest
      ? 'I confirm that I do have external business interest, the details of which are below:'
      : 'I confirm that I do not have any external business interest.';
  }

  public submitForm(): void {
    const ackDialogRef = this.ackDialog.open(ExternalBusinessInterestDeclarationAckDialogComponent, {
      data: {
        declarationDate: this.declarationDate
      },
      width: '800px'
    });

    ackDialogRef.afterClosed().subscribe((data) => {
      const value: ExternalBusinessInterestDeclarationToAdd = {
        additionalInformation: this.form.value.additionalInformation,
        businessInterests: this.hasExternalBusinessInterest
          ? this.businessInterests.value.map((interest) => ({
              ...interest,
              role: interest.role === this.other ? interest.roleOther : interest.role
            }))
          : null,
        employee: data.name,
        declarationDate: data.declarationDate
      };

      this.form.reset();
      this.form.disable();

      this.service.acknowledge$(value).subscribe(() => this.router.navigate(['/']));
    });
  }
}
