import { Component, OnInit } from '@angular/core';
import { ROLE_HK_USER } from '../shared/constants';
import { DeclarationsAuthService } from './declarations-auth.service';

@Component({
    selector: 'ad-hk-user',
    template: ` <span *ngIf="isAuthorized"> <ng-content></ng-content></span> `,
    standalone: false
})
export class HkUserComponent implements OnInit {
  public isAuthorized: boolean;

  constructor(private authService: DeclarationsAuthService) {}

  ngOnInit(): void {
    this.isAuthorized = this.authService.hasRole(ROLE_HK_USER);
  }
}
