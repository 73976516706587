import { Component, OnInit } from '@angular/core';
import { DeclarationsAuthService } from '../auth/declarations-auth.service';
import { ROLE_GVA_ADMIN, ROLE_HK_ADMIN, ROLE_UK_ADMIN } from '../shared/constants';

@Component({
    selector: 'ad-admin-dashboard',
    template: `
    <mat-card>
      <mat-tab-group *ngIf="hasMultipleAdminRoles">
        <mat-tab label="Hong Kong" *ngIf="authService.hasRole(ROLE_HK_ADMIN)">
          <ad-compliance-dashboard></ad-compliance-dashboard>
        </mat-tab>
        <mat-tab label="Geneva" *ngIf="authService.hasRole(ROLE_GVA_ADMIN)">
          <ad-gva-admin-dashboard></ad-gva-admin-dashboard>
        </mat-tab>
        <mat-tab label="UK" *ngIf="authService.hasRole(ROLE_UK_ADMIN)">
          <ad-uk-admin-dashboard></ad-uk-admin-dashboard>
        </mat-tab>
      </mat-tab-group>
      <ng-container *ngIf="!hasMultipleAdminRoles && authService.hasRole(ROLE_HK_ADMIN)">
        <mat-card-header>
          <mat-card-title> Hong Kong</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <ad-compliance-dashboard></ad-compliance-dashboard>
        </mat-card-content>
      </ng-container>
      <ng-container *ngIf="!hasMultipleAdminRoles && authService.hasRole(ROLE_GVA_ADMIN)">
        <mat-card-header>
          <mat-card-title> Geneva</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <ad-gva-admin-dashboard *ngIf="authService.hasRole(ROLE_GVA_ADMIN)"></ad-gva-admin-dashboard>
        </mat-card-content>
      </ng-container>
      <ng-container *ngIf="!hasMultipleAdminRoles && authService.hasRole(ROLE_UK_ADMIN)">
        <mat-card-header>
          <mat-card-title>UK</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <ad-uk-admin-dashboard *ngIf="authService.hasRole(ROLE_UK_ADMIN)"></ad-uk-admin-dashboard>
        </mat-card-content>
      </ng-container>
    </mat-card>
  `,
    styles: ['mat-card { padding: 0 }', 'mat-card-title { padding: 1rem }'],
    standalone: false
})
export class AdminDashboardComponent implements OnInit {
  public hasMultipleAdminRoles: boolean;
  public ROLE_HK_ADMIN = ROLE_HK_ADMIN;
  public ROLE_GVA_ADMIN = ROLE_GVA_ADMIN;
  public ROLE_UK_ADMIN = ROLE_UK_ADMIN;

  constructor(public authService: DeclarationsAuthService) {}

  ngOnInit(): void {
    let roleCount = 0;

    if (this.authService.hasRole(ROLE_GVA_ADMIN)) {
      roleCount++;
    }

    if (this.authService.hasRole(ROLE_HK_ADMIN)) {
      roleCount++;
    }

    if (this.authService.hasRole(ROLE_UK_ADMIN)) {
      roleCount++;
    }

    this.hasMultipleAdminRoles = roleCount > 0;
  }
}
