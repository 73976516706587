import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ROLE_UK_ADMIN, ROLE_UK_USER } from '../shared/constants';
import { DeclarationsAuthService } from './declarations-auth.service';

@Injectable({
  providedIn: 'root'
})
export class UkUserGuard  {
  constructor(private authService: DeclarationsAuthService, private router: Router) {}

  canActivate(): boolean {
    if (this.authService.hasRole(ROLE_UK_USER) || this.authService.hasRole(ROLE_UK_ADMIN)) {
      return true;
    } else {
      this.router.navigate(['/']);
    }
  }
}
