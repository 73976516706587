import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { showSuccessSnackbar, showErrorDialog } from 'src/app/shared/utils';
import { environment } from 'src/environments/environment';
import { UkSMCR, UkSMCRToAdd } from './uk-smcr.model';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class UkSmcrService {
  private apiEndpoint = `${environment.apiEndpoint}/api/uk/smcrs`;

  constructor(
    private http: HttpClient,
    private logger: NGXLogger,
    public apiErrorDialog: MatDialog,
    private snackBar: MatSnackBar
  ) {}

  getMine$(): Observable<UkSMCR> {
    return this.http.get<UkSMCR>(`${this.apiEndpoint}/me`).pipe(catchError(() => of(null)));
  }

  getMineFromLastYear$(): Observable<UkSMCR> {
    return this.http.get<UkSMCR>(`${this.apiEndpoint}/me/last-year`).pipe(catchError(() => of(null)));
  }

  getOne$(email: string): Observable<UkSMCR> {
    return this.http.get<UkSMCR>(`${this.apiEndpoint}/${email}`).pipe(catchError(() => of(null)));
  }

  acknowledge$(value: UkSMCRToAdd): Observable<void> {
    return this.http.post(this.apiEndpoint, value).pipe(
      map(() => showSuccessSnackbar(this.snackBar, 'Your declaration was successfully recorded')),
      catchError((error: any) => {
        this.logger.error(error);
        showErrorDialog(this.apiErrorDialog, error);
        return throwError(() => new Error(error));
      })
    );
  }

  public markAsComplianceChecked$(id: number, checkedBy: string, checked: Date): Observable<void> {
    return this.http
      .patch(`${this.apiEndpoint}/${id}/mark-compliance-checked`, {
        checkedBy,
        checked
      })
      .pipe(
        map(() => showSuccessSnackbar(this.snackBar, 'Declaration marked as checked successfully')),
        catchError((error: any) => {
          this.logger.error(error);
          showErrorDialog(this.apiErrorDialog, error);
          return throwError(() => new Error(error));
        })
      );
  }

  getS2Q1FunctionOptions$(): Observable<string[]> {
    return this.http.get<string[]>(`${this.apiEndpoint}/static/s2-q1-function-options`).pipe(catchError(() => of([])));
  }

  getS2Q1PrescribedResponsibilitiesOptions$(): Observable<string[]> {
    return this.http
      .get<string[]>(`${this.apiEndpoint}/static/s2-q1-prescribed-responsibilities-options`)
      .pipe(catchError(() => of([])));
  }

  getS2Q2Options$(): Observable<string[]> {
    return this.http.get<string[]>(`${this.apiEndpoint}/static/s2-q2-options`).pipe(catchError(() => of([])));
  }
}
