import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DeclarationsAuthService } from '../auth/declarations-auth.service';
import { valuesMatch } from '../shared/name-must-match.directive';
import { TitleCasePipe } from '@angular/common';

@Component({
  selector: 'ad-fit-and-proper-declaration-ack-dialog',
  template: `
    <h1 mat-dialog-title>ACKNOWLEDGMENT</h1>
    <div mat-dialog-content>
      <p>
        I, hereby confirm and declare that all the information provided herein to be, complete, true, and correct. I
        further understand and acknowledge that it is my personal responsibility to notify the licensed corporation as
        well as the SFC (for licenced individuals), immediately and in writing, in the event that any of the information
        contained herein changes.
      </p>
      <mat-form-field>
        <input
          matInput
          placeholder="Please input your full name"
          [(ngModel)]="data.name"
          adBlockCopyPaste
          [adNameMustMatch]="authService.account.name"
        />
        <mat-hint>Must match your full name in the corporate directory</mat-hint>
      </mat-form-field>
      <p>
        <strong>{{ data.declarationDate | date }}</strong>
      </p>
    </div>
    <div mat-dialog-actions>
      <button mat-button (click)="closeDialog()" [disabled]="!isValid()">SUBMIT</button>
    </div>
  `,
  providers: [TitleCasePipe]
})
export class FitAndProperDeclarationAckDialogComponent {
  constructor(
    public authService: DeclarationsAuthService,
    public dialogRef: MatDialogRef<FitAndProperDeclarationAckDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private titleCasePipe: TitleCasePipe
  ) {}

  public isValid(): boolean {
    return valuesMatch(this.data.name, this.authService.account.name);
  }

  public closeDialog() {
    this.data.name = this.titleCasePipe.transform(this.data.name);
    this.dialogRef.close(this.data);
  }
}
