import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'dhumanize',
  standalone: false
})
export class DhumanizePipe implements PipeTransform {
  transform(value: number): string {
    if (!value) {
      return null;
    }

    return moment.duration(value).humanize();
  }
}
