<mat-card class="px-0">
  <mat-card-header>
    <mat-card-title>Senior Managers &amp; Certification Regime</mat-card-title>
    <mat-card-subtitle>Individual Assessments, Statements of Responsibility and Certificates</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content class="mx-3">
    <form [formGroup]="form">
      <h6>1. Personal Particulars</h6>
      <p>Name: {{ user?.name }}</p>
      <p>Title: {{ user?.title }}</p>
      <mat-form-field class="w-25">
        <input matInput placeholder="IRN" formControlName="irn" />
        <mat-hint>If applicable</mat-hint>
      </mat-form-field>

      <!-- Section 2 -->
      <h6 class="mt-3">2. Annual SM&amp;CR Competence Assessment</h6>
      <ol>
        <li>
          <div class="d-flex justify-content-between align-items-center">
            <div class="flex-grow-1">
              Is the individual a Senior Manager?

              <!-- s2Q1HeldFunctions -->
              <mat-form-field *ngIf="form.get('s2Q1').value === true">
                <mat-label>If yes, what functions are held?</mat-label>
                <mat-select formControlName="s2Q1HeldFunctions" multiple>
                  <mat-option *ngFor="let option of s2Q1HeldFunctionsOptions$ | async" [value]="option">{{
                    option
                  }}</mat-option>
                </mat-select>
              </mat-form-field>

              <!-- s2Q1PrescribedResponsibilities -->
              <mat-form-field *ngIf="form.get('s2Q1').value === true">
                <mat-label>If yes, what prescribed responsibilities are they responsible for?</mat-label>
                <mat-select formControlName="s2Q1PrescribedResponsibilities" multiple>
                  <mat-option *ngFor="let option of s2Q1PrescribedResponsibilitiesOptions$ | async" [value]="option">{{
                    option
                  }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <mat-radio-group formControlName="s2Q1">
              <mat-radio-button [value]="true">Yes</mat-radio-button>
              <mat-radio-button [value]="false">No</mat-radio-button>
            </mat-radio-group>
          </div>
          <mat-error *ngIf="form.get('s2Q1').touched && form.get('s2Q1').hasError('required')"
            >This field is required.</mat-error
          >
        </li>
        <li>
          <div class="d-flex justify-content-between align-items-center">
            <div class="flex-grow-1">
              Is the individual subject to the Certification Regime?

              <!-- s2Q2HeldCertificationFunctions -->
              <mat-form-field *ngIf="form.get('s2Q2').value === true">
                <mat-label>If yes, what certification functions are held?</mat-label>
                <mat-select formControlName="s2Q2HeldCertificationFunctions" multiple>
                  <mat-option *ngFor="let option of s2Q2HeldCertificationFunctionsOptions$ | async" [value]="option">{{
                    option
                  }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <mat-radio-group formControlName="s2Q2">
              <mat-radio-button [value]="true">Yes</mat-radio-button>
              <mat-radio-button [value]="false">No</mat-radio-button>
            </mat-radio-group>
          </div>
          <mat-error *ngIf="form.get('s2Q2').touched && form.get('s2Q2').hasError('required')"
            >This field is required.</mat-error
          >
        </li>
        <li>
          Responsibilities
          <ol type="a">
            <li>
              <mat-form-field style="margin-top: 1rem">
                <mat-label>Indicate the nature of your role within the Company?</mat-label>
                <textarea matInput formControlName="s2Q3a" rows="4"></textarea>
                <mat-error *ngIf="form.get('s2Q3a').touched && form.get('s2Q3a').hasError('required')"
                  >This field is required.</mat-error
                >
              </mat-form-field>
            </li>
            <li class="align-top">
              <mat-form-field style="margin-top: 1rem">
                <mat-label
                  >Has the nature of your activities changed (e.g. reduced/expanded) in the past year?</mat-label
                >
                <textarea matInput formControlName="s2Q3b" rows="4"></textarea>
                <mat-error *ngIf="form.get('s2Q3b').touched && form.get('s2Q3b').hasError('required')"
                  >This field is required.</mat-error
                >
              </mat-form-field>
            </li>
          </ol>
        </li>
        <li>
          Reporting Line
          <mat-form-field style="margin-top: 1rem">
            <mat-label>To whom do you directly report to?</mat-label>
            <textarea matInput formControlName="s2Q4" rows="4"></textarea>
            <mat-error *ngIf="form.get('s2Q4').touched && form.get('s2Q4').hasError('required')"
              >This field is required.</mat-error
            >
          </mat-form-field>
        </li>
        <li>
          Compliance &amp; AML Procedures
          <mat-form-field style="margin-top: 1rem">
            <mat-label
              >Are you familiar with the Company&lsquo;s procedures detailed in the Compliance Manual and AML
              Handbook?</mat-label
            >
            <textarea matInput formControlName="s2Q5" rows="4"></textarea>
            <mat-error *ngIf="form.get('s2Q5').touched && form.get('s2Q5').hasError('required')"
              >This field is required.</mat-error
            >
          </mat-form-field>
        </li>
        <li>
          Fitness, Honesty &amp; Propriety
          <mat-form-field style="margin-top: 1rem">
            <mat-label
              >Are you aware of any changes in your personal circumstances which may affect your ongoing fitness and
              propriety?</mat-label
            >
            <textarea matInput formControlName="s2Q6" rows="4"></textarea>
            <mat-error *ngIf="form.get('s2Q6').touched && form.get('s2Q6').hasError('required')"
              >This field is required.</mat-error
            >
          </mat-form-field>
        </li>
        <li>
          Skills, Knowledge &amp; Expertise
          <ol type="a">
            <li>
              <mat-form-field style="margin-top: 1rem">
                <mat-label
                  >Please document an evaluation of your skills, expertise, and knowledge of the changes in the market,
                  to products, legislation and regulation. Are they up-to-date?</mat-label
                >
                <textarea matInput formControlName="s2Q7a" rows="4"></textarea>
                <mat-error *ngIf="form.get('s2Q7a').touched && form.get('s2Q7a').hasError('required')"
                  >This field is required.</mat-error
                >
              </mat-form-field>
            </li>
            <li>
              <mat-form-field style="margin-top: 1rem">
                <mat-label>Is your regulatory knowledge up-to-date?</mat-label>
                <textarea matInput formControlName="s2Q7b" rows="4"></textarea>
                <mat-error *ngIf="form.get('s2Q7b').touched && form.get('s2Q7b').hasError('required')"
                  >This field is required.</mat-error
                >
              </mat-form-field>
            </li>
          </ol>
        </li>
        <li>
          Training &amp; Qualifications
          <ol type="a">
            <li>
              <mat-form-field style="margin-top: 1rem">
                <mat-label
                  >What training have you been given during the past year? This includes training provided by us,
                  conferences, seminars, online and on-the-job training. Please also include any exams you may have
                  passed.</mat-label
                >
                <textarea matInput formControlName="s2Q8a" rows="4"></textarea>
                <mat-error *ngIf="form.get('s2Q8a').touched && form.get('s2Q8a').hasError('required')"
                  >This field is required.</mat-error
                >
              </mat-form-field>
            </li>
            <li>
              <mat-form-field style="margin-top: 1rem">
                <mat-label>Is there any area in which you feel you would benefit from receiving training?</mat-label>
                <textarea matInput formControlName="s2Q8b" rows="4"></textarea>
                <mat-error *ngIf="form.get('s2Q8b').touched && form.get('s2Q8b').hasError('required')"
                  >This field is required.</mat-error
                >
              </mat-form-field>
            </li>
          </ol>
        </li>
      </ol>

      <ng-container *ngIf="!onBehalfOf && alreadySubmitted">
        <h6>3. Declaration</h6>
        <p>
          I hereby confirm and declare that all the information provided herein to be complete, true, and correct
          regarding my fitness and propriety to perform the roles detailed above. I further understand and acknowledge
          that it is my personal responsibility to notify CIGP, immediately and in writing, in the event that any of the
          information contained herein changes.
        </p>
        <p>
          Name: {{ user?.name }}<br />
          Date: {{ declarationDate | date }}
        </p>
      </ng-container>
    </form>
  </mat-card-content>
  <mat-card-actions class="mx-3">
    <button *ngIf="alreadySubmitted; else canSubmitBlock" mat-button color="primary" disabled>
      YOU ALREADY SUBMITTED THIS DECLARATION
    </button>
    <ng-template #canSubmitBlock>
      <button mat-button color="primary" [disabled]="!form.valid" (click)="saveOrSubmitForm()">
        {{ onBehalfOf ? 'SAVE' : 'SUBMIT' }}
      </button>
    </ng-template>
  </mat-card-actions>
</mat-card>
