import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Moment } from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EmployeeHandbookService } from './employee-handbook.service';
import * as moment from 'moment';

@Component({
  selector: 'ad-employee-handbook',
  template: `
    <mat-card>
      <mat-card-header>
        <mat-card-title>Employee Handbook</mat-card-title>
        <mat-card-subtitle>
          Please read the employee handbook and acknowledge by clicking the button at the end of the page.
        </mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <ad-custom-pdf-viewer
          fileName="EmployeeHandbook.pdf"
          (endOfDocumentReached)="isAtEndOfDocument = true"
        ></ad-custom-pdf-viewer>
      </mat-card-content>
      <mat-card-actions>
        <button *ngIf="alreadyAcked$ | async; else canAckBlock" mat-button color="primary" disabled>
          YOUR ALREADY ACKNOWLEDGED READING THIS DOCUMENT
        </button>
        <ng-template #canAckBlock>
          <button mat-button color="primary" [disabled]="!isAtEndOfDocument" (click)="acknowledge()">
            ACKNOWLEDGE AND AGREE
          </button>
        </ng-template>
      </mat-card-actions>
    </mat-card>
  `
})
export class EmployeeHandbookComponent implements OnInit {
  public isAtEndOfDocument = false;
  public alreadyAcked$: Observable<boolean>;

  private start: Moment;

  constructor(private router: Router, private employeeHandbookService: EmployeeHandbookService) {}

  ngOnInit(): void {
    this.start = moment();
    this.alreadyAcked$ = this.employeeHandbookService.getMine$().pipe(map((item) => item && item.acknowledged));
  }

  public acknowledge(): void {
    this.employeeHandbookService
      .acknowledge$(moment.duration(moment().diff(this.start)).asMilliseconds())
      .subscribe(() => this.router.navigate(['/']));
  }
}
