import { Inject, Injectable } from '@angular/core';
import { MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { NGXLogger } from 'ngx-logger';
import { environment } from 'src/environments/environment';
import { AccountInfo, RedirectRequest } from '@azure/msal-browser';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

interface IDToken {
  roles?: string[];
}

@Injectable({
  providedIn: 'root'
})
export class DeclarationsAuthService {
  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private logger: NGXLogger
  ) {}

  public get loggedIn(): boolean {
    return !!this.authService.instance.getActiveAccount();
  }

  public get account(): AccountInfo {
    return this.authService.instance.getActiveAccount();
  }

  public checkAndSetActiveAccount(): void {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     */
    const activeAccount = this.authService.instance.getActiveAccount();

    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      const accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
      this.logger.log(this.account);
    }
  }

  public setActiveAccount(account: AccountInfo): void {
    this.authService.instance.setActiveAccount(account);
  }

  public login$(): Observable<void> {
    if (this.msalGuardConfig.authRequest) {
      return this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
    } else {
      return this.authService.loginRedirect();
    }
  }

  public logout$(): Observable<void> {
    return this.authService.logoutRedirect();
  }

  public hasRole(role: string): boolean {
    if (!this.account) {
      this.checkAndSetActiveAccount();
    }

    const typedIDToken = this.account.idTokenClaims as IDToken;
    return (typedIDToken && typedIDToken.roles && typedIDToken.roles.filter((r) => r === role).length > 0) || false;
  }

  public hasOneOfRoles(roles: string[]): boolean {
    if (!this.account) {
      this.checkAndSetActiveAccount();
    }

    const typedIDToken = this.account.idTokenClaims as IDToken;

    if (!typedIDToken || !typedIDToken.roles) {
      return false;
    }

    for (const role of roles) {
      if (typedIDToken.roles.filter((r) => r === role).length > 0) {
        return true;
      }
    }

    return false;
  }

  public getAccessToken(): Observable<string> {
    return this.authService
      .acquireTokenSilent({
        scopes: environment.consentScopes
      })
      .pipe(map((authResult) => authResult.accessToken));
  }
}
