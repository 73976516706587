<mat-card>
  <mat-card-header>
    <mat-card-title>Statement of Responsibilities</mat-card-title>
    <mat-card-subtitle>Private &amp; Confidential</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <ad-alert-data-preloaded *ngIf="hasLastYearData"></ad-alert-data-preloaded>
    <form [formGroup]="form">
      <!-- 1. Personal Particulars -->
      <mat-card class="mx-0 px-0">
        <mat-card-header>
          <mat-card-title>1. Personal Particulars</mat-card-title>
        </mat-card-header>
        <mat-card-content class="px-3">
          <p>Name: {{ user?.name }}</p>
          <p>Title: {{ user?.title }}</p>
          <mat-form-field class="w-25">
            <input matInput placeholder="IRN" formControlName="irn" />
            <mat-hint>If applicable</mat-hint>
          </mat-form-field>
        </mat-card-content>
      </mat-card>

      <!-- 2. Senior Management Functions -->
      <mat-card class="mx-0 px-0">
        <mat-card-header>
          <mat-card-title>2. Senior Management Functions</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <table formArrayName="seniorManagementFunctions" class="table">
            <thead>
              <tr>
                <th class="ps-3">Function</th>
                <th class="w-50">Description</th>
                <th>Type of Firm</th>
                <th>Effective Date</th>
                <th *ngIf="!alreadySubmitted"></th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="let seniorManagementFunction of seniorManagementFunctions.controls; let i = index"
                [formGroup]="getAsFormGroup(seniorManagementFunction)"
              >
                <td class="align-middle ps-3">
                  <mat-form-field>
                    <input matInput formControlName="function" />
                    <mat-error *ngIf="seniorManagementFunction.get('function').hasError('required')"
                      >Function is required</mat-error
                    >
                  </mat-form-field>
                </td>
                <td class="align-middle">
                  <mat-form-field>
                    <textarea matInput formControlName="description" rows="3"></textarea>
                    <mat-error *ngIf="seniorManagementFunction.get('description').hasError('required')"
                      >Description is required</mat-error
                    >
                  </mat-form-field>
                </td>
                <td class="align-middle">
                  <mat-form-field>
                    <input matInput formControlName="typeOfFirm" />
                    <mat-error *ngIf="seniorManagementFunction.get('typeOfFirm').hasError('required')"
                      >Type of firm is required</mat-error
                    >
                  </mat-form-field>
                </td>
                <td class="align-middle">
                  <mat-form-field>
                    <input
                      matInput
                      [matDatepicker]="datePicker"
                      formControlName="effectiveDate"
                      placeholder="DD/MM/YYYY"
                    />
                    <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
                    <mat-datepicker #datePicker></mat-datepicker>
                    <mat-error *ngIf="seniorManagementFunction.get('effectiveDate').hasError('required')"
                      >Effective date is required</mat-error
                    >
                  </mat-form-field>
                </td>
                <td class="align-middle" *ngIf="!alreadySubmitted">
                  <button mat-icon-button (click)="removeSeniorManagementFunction(i)">
                    <mat-icon>remove_circle_outline</mat-icon>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </mat-card-content>
        <mat-card-actions class="px-3" *ngIf="!alreadySubmitted">
          <button mat-icon-button (click)="addSeniorManagementFunction()" matTooltip="Add senior management function">
            <mat-icon>add_circle_outline</mat-icon>
          </button>
        </mat-card-actions>
      </mat-card>

      <!-- 3. Prescribed Responsibilities -->
      <mat-card class="mx-0 px-0">
        <mat-card-header>
          <mat-card-title>3. Prescribed Responsibilities</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <table formArrayName="prescribedResponsibilities" class="table">
            <thead>
              <tr>
                <th class="ps-3">FCA Ref</th>
                <th class="w-50">Prescribed Responsibility</th>
                <th>Shared</th>
                <th>Justification if shared</th>
                <th *ngIf="!alreadySubmitted"></th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="let responsibility of prescribedResponsibilities.controls; let i = index"
                [formGroup]="getAsFormGroup(responsibility)"
              >
                <td class="align-middle ps-3">
                  <mat-form-field>
                    <input matInput formControlName="fcaRef" />
                    <mat-error *ngIf="responsibility.get('fcaRef').hasError('required')">FCA Ref is required</mat-error>
                  </mat-form-field>
                </td>
                <td class="align-middle">
                  <mat-form-field>
                    <textarea matInput formControlName="responsibility" rows="3"></textarea>
                    <mat-error *ngIf="responsibility.get('responsibility').hasError('required')"
                      >Responsibility is required</mat-error
                    >
                  </mat-form-field>
                </td>
                <td class="align-middle">
                  <mat-slide-toggle formControlName="isShared">{{
                    responsibility.get('isShared').value ? 'YES' : 'NO'
                  }}</mat-slide-toggle>
                </td>
                <td class="align-middle">
                  <mat-form-field>
                    <textarea matInput formControlName="sharedJustification" rows="3"></textarea>
                    <mat-error *ngIf="responsibility.get('sharedJustification').hasError('required')"
                      >Shared justification is required</mat-error
                    >
                  </mat-form-field>
                </td>
                <td class="align-middle" *ngIf="!alreadySubmitted">
                  <button mat-icon-button (click)="removePrescribedResponsibility(i)">
                    <mat-icon>remove_circle_outline</mat-icon>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </mat-card-content>
        <mat-card-actions class="px-3" *ngIf="!alreadySubmitted">
          <button mat-icon-button (click)="addPrescribedResponsibility()" matTooltip="Add presribed responsibility">
            <mat-icon>add_circle_outline</mat-icon>
          </button>
        </mat-card-actions>
      </mat-card>

      <!-- 4. Other Responsibilities -->
      <mat-card class="mx-0 px-0">
        <mat-card-header>
          <mat-card-title>4. Other Responsibilities</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <table formArrayName="otherResponsibilities" class="table">
            <thead>
              <tr>
                <th class="ps-3">Title</th>
                <th class="w-50">Details</th>
                <th>Shared</th>
                <th>Justification if shared</th>
                <th *ngIf="!alreadySubmitted"></th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="let responsibility of otherResponsibilities.controls; let i = index"
                [formGroup]="getAsFormGroup(responsibility)"
              >
                <td class="align-middle ps-3">
                  <mat-form-field>
                    <input matInput formControlName="title" />
                    <mat-error *ngIf="responsibility.get('title').hasError('required')">Title is required</mat-error>
                  </mat-form-field>
                </td>
                <td class="align-middle">
                  <mat-form-field>
                    <textarea matInput formControlName="details" rows="3"></textarea>
                    <mat-error *ngIf="responsibility.get('details').hasError('required')"
                      >Details are required</mat-error
                    >
                  </mat-form-field>
                </td>
                <td class="align-middle">
                  <mat-slide-toggle formControlName="isShared">{{
                    responsibility.get('isShared').value ? 'YES' : 'NO'
                  }}</mat-slide-toggle>
                </td>
                <td class="align-middle">
                  <mat-form-field>
                    <textarea matInput formControlName="sharedJustification" rows="3"></textarea>
                    <mat-error *ngIf="responsibility.get('sharedJustification').hasError('required')"
                      >Shared justification is required</mat-error
                    >
                  </mat-form-field>
                </td>
                <td class="align-middle" *ngIf="!alreadySubmitted">
                  <button mat-icon-button (click)="removeOtherResponsibility(i)">
                    <mat-icon>remove_circle_outline</mat-icon>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </mat-card-content>
        <mat-card-actions class="px-3" *ngIf="!alreadySubmitted">
          <button mat-icon-button (click)="addOtherResponsibility()" matTooltip="Add other responsibility">
            <mat-icon>add_circle_outline</mat-icon>
          </button>
        </mat-card-actions>
      </mat-card>

      <ng-container *ngIf="!onBehalfOf && alreadySubmitted">
        <p><strong>Acknowledgement and Confirmation by the Employee:</strong></p>
        <p>
          Name: {{ user?.name }}<br />
          Date: {{ declarationDate | date }}
        </p>
      </ng-container>
    </form>
  </mat-card-content>
  <mat-card-actions>
    <button *ngIf="alreadySubmitted; else canSubmitBlock" mat-button color="primary" disabled>
      YOU ALREADY SUBMITTED THIS STATEMENT
    </button>
    <ng-template #canSubmitBlock>
      <button
        *ngIf="!onBehalfOf; else saveOnBehalfOfBlock"
        mat-button
        color="primary"
        [disabled]="!form.valid"
        (click)="submitForm()"
      >
        SUBMIT
      </button>
      <ng-template #saveOnBehalfOfBlock>
        <button mat-button color="primary" [disabled]="!form.valid" (click)="saveFormOnBehalOf()">SAVE</button>
      </ng-template>
    </ng-template>
  </mat-card-actions>
</mat-card>
