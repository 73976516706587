<table mat-table [dataSource]="dataSource" matSort>
  <!-- User Name -->
  <ng-container matColumnDef="userName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header style="cursor: pointer">User</th>
    <td mat-cell *matCellDef="let element" style="text-align: left">{{ element.userName }}</td>
  </ng-container>

  <!-- Fit & Proper Decl -->
  <ng-container matColumnDef="fitAndProperDeclarationStatus">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Fit &amp; Proper Decl</th>
    <td mat-cell *matCellDef="let element" class="text-center">
      <a [routerLink]="['/uk', 'fit-and-proper-declaration', element.userEmail]">
        <mat-icon *ngIf="element.fitAndProperDeclarationStatus === DeclarationStatus.NotCreated" class="text-danger"
          >close</mat-icon
        >
        <mat-icon *ngIf="element.fitAndProperDeclarationStatus === DeclarationStatus.Created" class="text-warning"
          >info_outlined</mat-icon
        >
      </a>
      <button
        *ngIf="element.fitAndProperDeclarationStatus === DeclarationStatus.Submitted"
        class="btn btn-sm"
        (click)="
          showComplianceCheckDialog(element.fitAndProperDeclarationId, DeclarationType.EmployeeFitAndProperDeclaration)
        "
      >
        <i class="bi bi-hourglass text-warning" title="Pending compliance check"></i>
      </button>
      <div
        class="d-flex justify-content-center align-items-center"
        *ngIf="element.fitAndProperDeclarationStatus === DeclarationStatus.ComplianceChecked"
      >
        <a [routerLink]="['/uk', 'fit-and-proper-declaration', element.userEmail]">
          <mat-icon class="text-success">check</mat-icon>
        </a>
        <button
          class="btn btn-sm"
          (click)="
            requestToGeneratePdfDeclaration(
              element.fitAndProperDeclarationId,
              DeclarationType.EmployeeFitAndProperDeclaration
            )
          "
        >
          <i class="bi bi-file-earmark-pdf" title="Regenerate PDF declaration"></i>
        </button>
      </div></td
  ></ng-container>

  <!-- SMCR -->
  <ng-container matColumnDef="smcrStatus">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>SM&amp;CR</th>
    <td mat-cell *matCellDef="let element" class="text-center">
      <a [routerLink]="['/uk', 'smcr', element.userEmail]">
        <mat-icon *ngIf="element.smcrStatus === DeclarationStatus.NotCreated" class="text-danger">close</mat-icon>
        <mat-icon *ngIf="element.smcrStatus === DeclarationStatus.Created" class="text-warning">info_outlined</mat-icon>
      </a>
      <button
        *ngIf="element.smcrStatus === DeclarationStatus.Submitted"
        class="btn btn-sm"
        (click)="showComplianceCheckDialog(element.smcrId, DeclarationType.Smcr)"
      >
        <i class="bi bi-hourglass text-warning" title="Pending compliance check"></i>
      </button>
      <div
        class="d-flex justify-content-center align-items-center"
        *ngIf="element.smcrStatus === DeclarationStatus.ComplianceChecked"
      >
        <a [routerLink]="['/uk', 'smcr', element.userEmail]">
          <mat-icon class="text-success">check</mat-icon>
        </a>
        <button class="btn btn-sm" (click)="requestToGeneratePdfDeclaration(element.smcrId, DeclarationType.Smcr)">
          <i class="bi bi-file-earmark-pdf" title="Regenerate PDF declaration"></i>
        </button>
      </div></td
  ></ng-container>

  <!-- Statement of Responsibilities -->
  <ng-container matColumnDef="statementOfResponsibilitiesStatus">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>SoR</th>
    <td mat-cell *matCellDef="let element" class="text-center">
      <a [routerLink]="['/uk', 'statement-of-responsibilities', element.userEmail]">
        <mat-icon *ngIf="element.statementOfResponsibilitiesStatus === DeclarationStatus.NotCreated" class="text-danger"
          >close</mat-icon
        >
        <mat-icon *ngIf="element.statementOfResponsibilitiesStatus === DeclarationStatus.Created" class="text-warning"
          >info_outlined</mat-icon
        >
      </a>
      <button
        *ngIf="element.statementOfResponsibilitiesStatus === DeclarationStatus.Submitted"
        class="btn btn-sm"
        (click)="
          showComplianceCheckDialog(element.statementOfResponsibilitiesId, DeclarationType.StatementOfResponsibilities)
        "
      >
        <i class="bi bi-hourglass text-warning" title="Pending compliance check"></i>
      </button>
      <div
        class="d-flex justify-content-center align-items-center"
        *ngIf="element.statementOfResponsibilitiesStatus === DeclarationStatus.ComplianceChecked"
      >
        <a [routerLink]="['/uk', 'statement-of-responsibilities', element.userEmail]">
          <mat-icon class="text-success">check</mat-icon>
        </a>
        <button
          class="btn btn-sm"
          (click)="
            requestToGeneratePdfDeclaration(
              element.statementOfResponsibilitiesId,
              DeclarationType.StatementOfResponsibilities
            )
          "
        >
          <i class="bi bi-file-earmark-pdf" title="Regenerate PDF declaration"></i>
        </button>
      </div></td
  ></ng-container>

  <!-- Compliance Manual -->
  <ng-container matColumnDef="ackedComplianceManual">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Compl Manual</th>
    <td mat-cell *matCellDef="let element" class="text-center">
      <span
        *ngIf="element.ackedComplianceManual; else noBlock"
        [title]="element.complianceManualReadingTimeMs | dhumanize"
      >
        <mat-icon class="text-success">check</mat-icon>
      </span>
    </td></ng-container
  >

  <!-- Completion Date -->
  <ng-container matColumnDef="completionDate">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Completion Date</th>
    <td mat-cell *matCellDef="let element">
      {{ element.completionDate | date }}
    </td></ng-container
  >

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let element; columns: displayedColumns" class="element-row"></tr>

  <ng-template #noBlock>
    <mat-icon class="text-danger">close</mat-icon>
  </ng-template>
</table>
<mat-spinner *ngIf="loading"></mat-spinner>
