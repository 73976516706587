<mat-sidenav-container class="sidenav-container" style="min-height: 100%">
  <mat-sidenav-content>
    <mat-toolbar class="position-fixed d-flex justify-content-between" style="z-index: 1">
      <div class="d-flex align-items-center gap-3">
        <a [routerLink]="['/']" style="color: black">
          <img src="assets/cigp-logo.png" style="width: 40px" title="CIGP" />
        </a>
        <a [routerLink]="['/']" style="color: black">{{ title }}</a>
        <span *ngIf="!production" class="badge bg-success text-white">TEST</span>
      </div>

      <div class="d-flex justify-content-end align-items-center gap-3">
        <a *ngIf="feedbackForm" [href]="feedbackForm" target="_blank" class="fs-6 text-black-50">FEEDBACK</a>
        <a [href]="userManual" target="_blank" class="p-0 fs-6 text-black-50">
          <i class="bi bi-question-circle"></i>
        </a>
        <ng-container *ngIf="loggedIn; else loginBlock">
          <button mat-button [matMenuTriggerFor]="userMenu">{{ menuLabel | uppercase }}</button>
          <mat-menu #userMenu="matMenu">
            <button mat-menu-item (click)="logout()">LOGOUT</button>
          </mat-menu>
        </ng-container>
        <ng-template #loginBlock>
          <a mat-button [routerLink]="['/login']">LOGIN</a>
        </ng-template>
      </div>
    </mat-toolbar>
    <div class="content">
      <!--This is to avoid reload during acquireTokenSilent() because of hidden iframe -->
      <router-outlet *ngIf="!isIframe"></router-outlet>
    </div>
    <footer>&copy; 2024 CIGP</footer>
  </mat-sidenav-content>
</mat-sidenav-container>
