<mat-card>
  <mat-card-header>
    <mat-card-title>Employee Personal Account Dealing Declaration Form</mat-card-title>
    <mat-card-subtitle>
      <p>
        To: Legal and Compliance Department<br />
        Compagnie d'Investissements et de Gestion Priv&eacute;e (Hong Kong) Limited (&ldquo;CIGP HK&rdquo;)<br />
        Re: Employee Personal Account Dealing Declaration
      </p>
    </mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <ad-alert-data-preloaded *ngIf="hasLastYearData && !alreadySubmitted"></ad-alert-data-preloaded>
    <form [formGroup]="form">
      <p>Please note that I, {{ authService.account.name }}, would like to notify CIGP HK on the following:</p>

      <!-- Personal dealing accounts -->
      <mat-slide-toggle formControlName="hasPersonalDealingAccounts" class="d-block">{{
        hasPersonalDealingAccountsLabel
      }}</mat-slide-toggle>
      <ng-container formArrayName="personalDealingAccounts" *ngIf="hasPersonalDealingAccounts">
        <div
          class="d-flex align-items-center"
          *ngFor="let dealingAccount of personalDealingAccounts.controls; let i = index"
          [formGroup]="getAsFormGroup(dealingAccount)"
        >
          <mat-card class="flex-grow-1">
            <mat-card-header>
              <mat-card-subtitle>Dealing Account {{ i + 1 }}</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
              <mat-form-field>
                <mat-label>Name of broker / investment plan</mat-label>
                <input matInput formControlName="brokerName" />
                <mat-error *ngIf="dealingAccount.get('brokerName').hasError('required')"
                  >Name of broker / investment plan is required</mat-error
                >
              </mat-form-field>
              <mat-form-field>
                <input matInput placeholder="Account number" formControlName="accountNumber" />
                <mat-error *ngIf="dealingAccount.get('accountNumber').hasError('required')"
                  >Account number is required</mat-error
                >
              </mat-form-field>
            </mat-card-content>
          </mat-card>
          <button
            mat-icon-button
            (click)="removePersonalDealingAccount(i)"
            *ngIf="personalDealingAccounts.length > 1"
            [disabled]="alreadySubmitted"
          >
            <mat-icon>remove_circle_outline</mat-icon>
          </button>
        </div>
        <div>
          <button
            mat-icon-button
            (click)="addPersonalDealingAccount()"
            matTooltip="Add dealing account"
            [disabled]="alreadySubmitted"
          >
            <mat-icon>add_circle_outline</mat-icon>
          </button>
        </div>
      </ng-container>

      <!-- Related persons -->
      <mat-slide-toggle formControlName="hasRelatedPersons" class="d-block"
        >{{ hasRelatedPersonsLabel }}<sup>1</sup></mat-slide-toggle
      >
      <ng-container formArrayName="relatedPersons" *ngIf="hasRelatedPersons">
        <div
          class="d-flex align-items-center"
          *ngFor="let relatedPerson of relatedPersons.controls; let i = index"
          [formGroup]="getAsFormGroup(relatedPerson)"
        >
          <mat-card class="flex-grow-1">
            <mat-card-header>
              <mat-card-subtitle>Related Person {{ i + 1 }}</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
              <mat-form-field>
                <mat-label>Name</mat-label>
                <input matInput formControlName="name" />
                <mat-error *ngIf="relatedPerson.get('name').hasError('required')">Name is required</mat-error>
              </mat-form-field>
              <mat-form-field>
                <textarea matInput placeholder="Address" formControlName="address" rows="3"></textarea>
                <mat-error *ngIf="relatedPerson.get('address').hasError('required')">Address is required</mat-error>
              </mat-form-field>
              <mat-form-field>
                <input matInput placeholder="Relationship" formControlName="relationship" />
                <mat-error *ngIf="relatedPerson.get('relationship').hasError('required')"
                  >Relationship is required</mat-error
                >
              </mat-form-field>

              <!-- Related person dealing accounts -->
              <ng-container formArrayName="dealingAccounts">
                <div
                  class="d-flex align-items-center"
                  *ngFor="let dealingAccount of getRelatedPersonDealingAccounts(i).controls; let i1 = index"
                  [formGroup]="getAsFormGroup(dealingAccount)"
                >
                  <mat-card class="flex-grow-1">
                    <mat-card-header>
                      <mat-card-subtitle>Dealing Account {{ i1 + 1 }}</mat-card-subtitle>
                    </mat-card-header>
                    <mat-card-content>
                      <mat-form-field>
                        <mat-label>Name of broker / investment plan</mat-label>
                        <input matInput formControlName="brokerName" />
                        <mat-error *ngIf="dealingAccount.get('brokerName').hasError('required')"
                          >Name of broker / investment plan is required</mat-error
                        >
                      </mat-form-field>
                      <mat-form-field>
                        <input matInput placeholder="Account number" formControlName="accountNumber" />
                        <mat-error *ngIf="dealingAccount.get('accountNumber').hasError('required')"
                          >Account number is required</mat-error
                        >
                      </mat-form-field>
                    </mat-card-content>
                  </mat-card>
                  <button
                    mat-icon-button
                    (click)="removeRelatedPersonDealingAccount(i, i1)"
                    *ngIf="getRelatedPersonDealingAccounts(i).length > 1"
                  >
                    <mat-icon>remove_circle_outline</mat-icon>
                  </button>
                </div>
                <button
                  mat-icon-button
                  (click)="addRelatedPersonDealingAccount(i)"
                  matTooltip="Add dealing account"
                  [disabled]="alreadySubmitted"
                >
                  <mat-icon>add_circle_outline</mat-icon>
                </button>
              </ng-container>
            </mat-card-content>
          </mat-card>
          <button
            mat-icon-button
            (click)="removeRelatedPerson(i)"
            *ngIf="relatedPersons.length > 1"
            [disabled]="alreadySubmitted"
          >
            <mat-icon>remove_circle_outline</mat-icon>
          </button>
        </div>
        <div>
          <button
            mat-icon-button
            (click)="addRelatedPerson()"
            matTooltip="Add related person"
            [disabled]="alreadySubmitted"
          >
            <mat-icon>add_circle_outline</mat-icon>
          </button>
        </div>
      </ng-container>
      <p>
        <small>
          Related Person can be defined as (i) member(s) of an Employee's immediate family (including spouse or civil
          partner, any minor child (natural or adopted) or minor step-child) or persons living in the same household for
          at least one year on the date of the transaction or otherwise closely connected, (ii) legal or other entities
          connected to an Employee (e.g. companies, trusts and estates with which he/she is related or with which he/she
          has a shared interest), (iii) other natural persons whose services are placed at the disposal and under the
          control of CIGP HK and an outsource provider whose purpose is the provision of regulated activities and other
          account(s), or (iv) account on which such Employee has a controlling power or exercises significant
          influence.</small
        >
      </p>

      <p>
        I hereby acknowledge and confirm my agreements with the &ldquo;Employee Personal Account Dealing Policy&rdquo;
        and agree to the following:
      </p>
      <p>
        <mat-checkbox formControlName="willNotDeal"
          >I will not deal, trade, invest, and/or divest any financial instruments in my name or through the account of
          a Related Person<br />
          or any other account under my controlling power unless specifically approved by CIGP HK.</mat-checkbox
        >
        <mat-error *ngIf="form.get('willNotDeal').touched && form.get('willNotDeal').hasError('required')"
          >This field is required</mat-error
        >
      </p>
      <p>
        <mat-checkbox formControlName="willSeekApprovalToTrade"
          >I will seek approval from CIGP HK to engage in any deal, trade, investment, and/or divestment as listed in
          the &ldquo;Personal Account Dealing Application Policy&rdquo;<br />
          by completing the &ldquo;Personal Account Dealing Request Form&rdquo;.</mat-checkbox
        >
        <mat-error
          *ngIf="
            form.get('willSeekApprovalToTrade').touched && form.get('willSeekApprovalToTrade').hasError('required')
          "
          >This field is required</mat-error
        >
      </p>
      <p>
        <mat-checkbox formControlName="willSendTradeConfirmationsAndStatements"
          >I confirm all electronic/physical copies of trade confirmations and monthly broker statements will be sent to
          CIGP HK to the address below:</mat-checkbox
        >
      </p>
      <mat-error
        *ngIf="
          form.get('willSendTradeConfirmationsAndStatements').touched &&
          form.get('willSendTradeConfirmationsAndStatements').hasError('required')
        "
        >This field is required</mat-error
      >
      <address style="margin: 1rem 0 1rem 2rem">
        Compagnie d'Investissements et de Gestion Priv&eacute;e (Hong Kong) Limited<br />
        2/F Hollywood Commercial House,<br />
        3-5 Old Bailey Street, Central,<br />
        Hong Kong S.A.R.<br />
        <br />
        OR<br />
        <br />
        Attention: Legal and Compliance Department<br />
        Email: <a href="mailto:hrc@cigp.com">HRC&#64;cigp.com</a>
      </address>
      <p>
        <mat-checkbox formControlName="willSeekApprovalToOpenDealingAccount"
          >I will seek approval from CIGP HK before opening new Personal Dealing Accounts.</mat-checkbox
        >
        <mat-error
          *ngIf="
            form.get('willSeekApprovalToOpenDealingAccount').touched &&
            form.get('willSeekApprovalToOpenDealingAccount').hasError('required')
          "
          >This field is required</mat-error
        >
      </p>

      <!-- Additional information -->
      <h4>Additional information</h4>
      <mat-form-field>
        <mat-label>Additional information (optional)</mat-label>
        <textarea matInput formControlName="additionalInformation" rows="4"></textarea>
      </mat-form-field>

      <ng-container *ngIf="alreadySubmitted">
        <!-- Acknowledgement -->
        <h4>Acknowledgement and Confirmation by the Employee:</h4>
        <p>
          Name: {{ employee }}<br />
          Date: {{ declarationDate | date }}
        </p>
      </ng-container>
    </form>
  </mat-card-content>
  <mat-card-actions>
    <button *ngIf="alreadySubmitted; else canSubmitBlock" mat-button color="primary" disabled>
      YOU ALREADY SUBMITTED THIS DECLARATION
    </button>
    <ng-template #canSubmitBlock>
      <button mat-button color="primary" [disabled]="!form.valid" (click)="submitForm()">SUBMIT</button>
    </ng-template>
  </mat-card-actions>
</mat-card>
