import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

@Directive({
  selector: '[adNameMustMatch]',
  standalone: true,
  providers: [{ provide: NG_VALIDATORS, useExisting: NameMustMatchDirective, multi: true }]
})
export class NameMustMatchDirective implements Validator {
  @Input('adNameMustMatch') name: string;

  validate(control: AbstractControl<any, any>): ValidationErrors | null {
    return !control.value || !valuesMatch(this.name, control.value) ? { nameMustMatch: true } : null;
  }
}

export function valuesMatch(value1: string, value2: string) {
  return value1 && value2 && value1.toLowerCase() === value2.toLowerCase();
}
