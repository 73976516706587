import { MsalGuardConfiguration, MsalInterceptorConfiguration } from '@azure/msal-angular';
import {
  BrowserCacheLocation,
  InteractionType,
  IPublicClientApplication,
  LogLevel,
  PublicClientApplication
} from '@azure/msal-browser';
import { environment } from '../../environments/environment';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

export function loggerCallback(logLevel: LogLevel, message: string): void {
  if (!environment.production && logLevel <= LogLevel.Warning) {
    console.log(message);
  }
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.clientId,
      authority: environment.authority,
      redirectUri: '/',
      postLogoutRedirectUri: '/'
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE // set to true for IE 11. Remove this line to use Angular Universal
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    }
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set(`${environment.apiEndpoint}/api/compliance-dashboard`, environment.consentScopes);
  protectedResourceMap.set(`${environment.apiEndpoint}/api/compliance-manual`, environment.consentScopes);
  protectedResourceMap.set(
    `${environment.apiEndpoint}/api/employee-fit-and-proper-declaration`,
    environment.consentScopes
  );
  protectedResourceMap.set(`${environment.apiEndpoint}/api/employee-handbook`, environment.consentScopes);
  protectedResourceMap.set(
    `${environment.apiEndpoint}/api/ethic-standards-and-corporate-culture`,
    environment.consentScopes
  );
  protectedResourceMap.set(
    `${environment.apiEndpoint}/api/external-business-interest-declaration`,
    environment.consentScopes
  );
  protectedResourceMap.set(`${environment.apiEndpoint}/api/hk/dr-bcp-acks`, environment.consentScopes);
  protectedResourceMap.set(
    `${environment.apiEndpoint}/api/personal-account-dealing-declaration`,
    environment.consentScopes
  );
  protectedResourceMap.set(`${environment.apiEndpoint}/api/personal-account-dealing-policy`, environment.consentScopes);
  protectedResourceMap.set(`${environment.apiEndpoint}/api/gva/admin-dashboard`, environment.consentScopes);
  protectedResourceMap.set(
    `${environment.apiEndpoint}/api/gva/employee-fit-and-proper-declaration`,
    environment.consentScopes
  );
  protectedResourceMap.set(`${environment.apiEndpoint}/api/pdf-declarations`, environment.consentScopes);
  protectedResourceMap.set(`${environment.apiEndpoint}/api/static/pdf-viewer`, environment.consentScopes);
  protectedResourceMap.set(`${environment.apiEndpoint}/api/static/users`, environment.consentScopes);
  protectedResourceMap.set(`${environment.apiEndpoint}/api/uk/admin-dashboard`, environment.consentScopes);
  protectedResourceMap.set(`${environment.apiEndpoint}/api/uk/compliance-manual`, environment.consentScopes);
  protectedResourceMap.set(
    `${environment.apiEndpoint}/api/uk/employee-fit-and-proper-declarations`,
    environment.consentScopes
  );
  protectedResourceMap.set(`${environment.apiEndpoint}/api/uk/smcrs`, environment.consentScopes);
  protectedResourceMap.set(
    `${environment.apiEndpoint}/api/uk/smcrs/static/s2-q1-function-options`,
    environment.consentScopes
  );
  protectedResourceMap.set(
    `${environment.apiEndpoint}/api/uk/smcrs/static/s2-q1-prescribed-responsibilities-options`,
    environment.consentScopes
  );
  protectedResourceMap.set(`${environment.apiEndpoint}/api/uk/smcrs/static/s2-q2-options`, environment.consentScopes);
  protectedResourceMap.set(
    `${environment.apiEndpoint}/api/uk/statement-of-responsibilities`,
    environment.consentScopes
  );
  protectedResourceMap.set(`${environment.cigpPortalApi.endpoint}/api/users`, environment.cigpPortalApi.consentScopes);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: environment.consentScopes
    },
    loginFailedRoute: '/login-failed'
  };
}
