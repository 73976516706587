import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ROLE_GVA_USER } from '../shared/constants';
import { DeclarationsAuthService } from './declarations-auth.service';

@Injectable({
  providedIn: 'root'
})
export class GvaUserGuard  {
  constructor(private authService: DeclarationsAuthService, private router: Router) {}

  canActivate(): boolean {
    if (this.authService.hasRole(ROLE_GVA_USER)) {
      return true;
    } else {
      this.router.navigate(['/']);
    }
  }
}
