import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'ad-api-error-dialog',
  template: `
    <h1 mat-dialog-title>ERROR</h1>
    <div mat-dialog-content>
      <div [ngSwitch]="data.status">
        <!-- HTTP 400 -->
        <ng-container *ngSwitchCase="400">
          <h4>Please fix the following validation errors:</h4>
          <ng-container *ngIf="jsonError">
            <ul *ngIf="jsonError.errors; else jsonErrBlock">
              <li *ngFor="let member of jsonError.errors | keyvalue">
                <strong>{{ member.key }}:</strong> {{ member.value | json }}
              </li>
            </ul>
            <ng-template #jsonErrBlock>
              <p>{{ jsonError | json }}</p>
            </ng-template>
          </ng-container>
        </ng-container>

        <!-- HTTP 429 -->
        <ng-container *ngSwitchCase="429">
          <h4>Limit exceeded:</h4>
          <p *ngIf="data.message; else errBlock429">{{ data.message }}</p>
          <ng-template #errBlock429>
            <p>{{ data.error || data | json }}</p>
          </ng-template>
        </ng-container>

        <!-- Other Errors -->
        <ng-container *ngSwitchDefault>
          <strong>HTTP {{ data.status }}:</strong> {{ textError || data.error || data | json }}
        </ng-container>
      </div>
    </div>
    <div mat-dialog-actions>
      <button mat-button [mat-dialog-close]="data" cdkFocusInitial>OK</button>
    </div>
  `
})
export class ApiErrorDialogComponent implements OnInit {
  public jsonError: any;
  public textError: string;

  constructor(public dialogRef: MatDialogRef<ApiErrorDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    if (data.error) {
      if (typeof data.error === 'string') {
        try {
          this.jsonError = JSON.parse(data.error);
        } catch (error) {
          console.error(error);
        }
      } else {
        this.jsonError = data.error;
      }
    }
  }

  async ngOnInit(): Promise<void> {
    if (!this.jsonError) {
      this.textError = await this.data;
    }
  }

  close(): void {
    this.dialogRef.close();
  }
}
