const apiEndpoint = 'https://cigpdecapiuat.azurewebsites.net';

export const environment = {
  production: false,
  apiEndpoint,
  authority: 'https://login.microsoftonline.com/6f41eebe-f4f6-41f6-9d01-03d70104f18c/',
  clientId: 'fd24605a-7bf9-4d4f-a383-c7d5ae7da887',
  consentScopes: ['api://c46a1148-03e9-4efc-a7c1-6774bf51ffb6/user_impersonation'],
  pdfServiceEndpoint: `${apiEndpoint}/api/static/pdf-viewer`,
  userManual: 'https://documentation.cigp.app/books/cigp-declarations',
  feedbackForm: 'https://forms.office.com/r/CryHnuh721',
  cigpPortalApi: {
    endpoint: 'https://cigp-portal-api.azurewebsites.net',
    consentScopes: ['api://1b86d041-9931-422e-893a-2bd531e9c692/user_impersonation']
  }
};
