import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { map } from 'rxjs/operators';
import { UsersService } from 'src/app/core/users.service';
import { AdminDashboardItem } from './admin-dashboard.model';
import { AdminDashboardService } from './admin-dashboard.service';
import * as moment from 'moment';
import { PdfDeclarationsService } from 'src/app/pdf-declarations/pdf-declarations.service';
import { DeclarationType } from 'src/app/shared/declaration-type.enum';
import { OfficeLocation } from 'src/app/shared/office-location.enum';

@Component({
  selector: 'ad-gva-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.css']
})
export class GVAAdminDashboardComponent implements OnInit {
  public displayedColumns: string[] = ['userName', 'prevFitAndProperDeclaration', 'curFitAndProperDeclaration'];
  public dataSource: MatTableDataSource<AdminDashboardItem>;

  public loading = true;

  public curDisplayDate: string;
  public prevDisplayDate: string;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  public DeclarationType = DeclarationType;

  constructor(
    private dashboardService: AdminDashboardService,
    private usersService: UsersService,
    private pdfDeclarationsService: PdfDeclarationsService
  ) {}

  ngOnInit(): void {
    this.dashboardService
      .listDashboardItems$()
      .pipe(
        map((items) =>
          items.map((item) => ({
            ...item,
            joinedAfterCurDecl$: this.usersService
              .getJoiningDate$(item.userEmail)
              .pipe(map((joined) => joined && joined.isAfter(moment(new Date(item.curYear, item.curMonth, 1)))))
          }))
        )
      )
      .subscribe((items) => {
        if (items && items.length > 0) {
          this.curDisplayDate = items[0].curDateDisplay;
          this.prevDisplayDate = items[0].prevDateDisplay;
        }
        this.dataSource = new MatTableDataSource(items);
        this.dataSource.sort = this.sort;
        this.loading = false;
      });
  }

  public requestToGeneratePdfDeclaration(declarationId: number, declarationType: DeclarationType): void {
    this.pdfDeclarationsService
      .generatePdfDeclaration$({
        declarationType,
        declarationId,
        officeLocation: OfficeLocation.Geneva
      })
      .subscribe();
  }
}
