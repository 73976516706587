import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { ComplianceDashboardItem } from './compliance-dashboard.model';
import { ComplianceDashboardService } from './compliance-dashboard.service';
import { PdfDeclarationsService } from 'src/app/pdf-declarations/pdf-declarations.service';
import { DeclarationType } from 'src/app/shared/declaration-type.enum';
import { OfficeLocation } from 'src/app/shared/office-location.enum';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { DeclarationComplianceCheckDialogComponent } from 'src/app/pdf-declarations/declaration-compliance-check-dialog.component';
import { DeclarationStatus } from 'src/app/shared/declaration-status.enum';
import { PdfDeclarationRequest } from 'src/app/pdf-declarations/pdf-declaration-request.model';

@Component({
    selector: 'ad-compliance-dashboard',
    templateUrl: './compliance-dashboard.component.html',
    styleUrls: ['./compliance-dashboard.component.css'],
    standalone: false
})
export class ComplianceDashboardComponent implements OnInit {
  public displayedColumns: string[] = [
    'userName',
    'ackedComplianceManual',
    'ackedDrBcpPlan',
    'ackedEmployeeHandbook',
    'ackedEthicStandardsAndCorporateCulture',
    'externalBusinessInterestDeclarationStatus',
    'fitAndProperDeclarationStatus',
    'ackedPersonalAccountDealingPolicy',
    'personalAccountDealingDeclarationStatus',
    'completionDate'
  ];
  public dataSource: MatTableDataSource<ComplianceDashboardItem>;

  public DeclarationType = DeclarationType;
  public DeclarationStatus = DeclarationStatus;
  public loading = true;

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private complianceDashboardService: ComplianceDashboardService,
    private pdfDeclarationsService: PdfDeclarationsService,
    public checkDialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.loadComplianceDashboardItems();
  }

  public requestToGeneratePdfDeclaration(declarationId: number, declarationType: DeclarationType): void {
    this.pdfDeclarationsService
      .generatePdfDeclaration$({
        declarationType,
        declarationId,
        officeLocation: OfficeLocation.HongKong
      })
      .subscribe();
  }

  public showComplianceCheckDialog(declarationId: number, declarationType: DeclarationType) {
    const request: PdfDeclarationRequest = {
      declarationId,
      declarationType,
      officeLocation: OfficeLocation.HongKong
    };

    const ackDialogRef = this.checkDialog.open(DeclarationComplianceCheckDialogComponent, {
      data: request,
      minWidth: '1000px'
    });

    ackDialogRef.afterClosed().subscribe((data) => {
      this.pdfDeclarationsService
        .markDeclarationAsComplianceChecked$(request, data.name, data.date)
        .subscribe(() => this.loadComplianceDashboardItems());
    });
  }

  private loadComplianceDashboardItems(): void {
    this.complianceDashboardService.listComplianceDashboardItems$().subscribe((items) => {
      this.dataSource = new MatTableDataSource(items);
      this.dataSource.sort = this.sort;
      this.loading = false;
    });
  }
}
