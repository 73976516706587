import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import moment from 'moment';
import { NGXLogger } from 'ngx-logger';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { showErrorDialog } from '../shared/utils';
import { BasicUser } from './basic-user.model';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  private apiEndpoint = `${environment.apiEndpoint}/api/static/users`;
  private portalApiEndpoint = environment.cigpPortalApi.endpoint
    ? `${environment.cigpPortalApi.endpoint}/api/users`
    : null;

  private joiningDates: { [key: string]: moment.Moment } = {};
  private joiningDates$: { [key: string]: Observable<moment.Moment> } = {};

  constructor(
    private http: HttpClient,
    private logger: NGXLogger,
    public apiErrorDialog: MatDialog
  ) {}

  getCurrentUserCorporateTitle$(): Observable<string> {
    return this.http
      .get(`${this.apiEndpoint}/current/corporate-title`, {
        responseType: 'text'
      })
      .pipe(
        map((title: string) => title),
        catchError((error: any) => {
          this.logger.error(error);
          showErrorDialog(this.apiErrorDialog, error);
          return throwError(() => new Error(error));
        })
      );
  }

  getOne$(email: string): Observable<BasicUser> {
    return this.http.get<BasicUser>(`${this.apiEndpoint}/${email}`);
  }

  public getJoiningDate$(email: string): Observable<moment.Moment | null> {
    if (this.joiningDates[email]) {
      return of(this.joiningDates[email]);
    }

    if (!this.joiningDates$[email]) {
      this.joiningDates$[email] = this.apiEndpoint
        ? this.http
            .get(`${this.portalApiEndpoint}/${email}/joined`, {
              responseType: 'text'
            })
            .pipe(
              map((joined) => (joined ? moment(joined.replace(/"/g, '')) : null)),
              tap((joined) => (this.joiningDates[email] = joined)),
              catchError((error: any) => {
                this.logger.error(error);
                return of(moment('2000-01-01'));
              })
            )
        : of(null);
    }

    return this.joiningDates$[email];
  }
}
