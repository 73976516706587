import { ApiError } from '../core/api-error.model';
import { ApiErrorDialogComponent } from '../core/api-error-dialog.component';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';

export function showErrorDialog(apiErrorDialog: MatDialog, error: ApiError): void {
  apiErrorDialog.open(ApiErrorDialogComponent, {
    width: '800px',
    data: error
  });
}

export function showSuccessSnackbar(snackBar: MatSnackBar, message: string): void {
  if (message) {
    snackBar.open(message, null, {
      duration: 2000
    });
  }
}

export function getAsFormGroup(control: AbstractControl): UntypedFormGroup {
  return control as UntypedFormGroup;
}

export function initials(name: string): string {
  const value = name
    .toUpperCase()
    .split(' ')
    .map((w) => w[0])
    .join('');

  switch (value) {
    case 'AS':
      return 'ASA';
    case 'HDSS':
      return 'HSS';
    default:
      return value;
  }
}

export function yesNo(value: boolean): string {
  if (value === null || value === undefined) {
    return null;
  }
  return !!value ? 'Yes' : 'No';
}
