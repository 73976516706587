import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DeclarationStatus } from 'src/app/shared/declaration-status.enum';
import { UKAdminDashboardItem } from './uk-admin-dashboard-item.model';
import { UkAdminDashboardService } from './uk-admin-dashboard.service';
import { DeclarationType } from 'src/app/shared/declaration-type.enum';
import { PdfDeclarationsService } from 'src/app/pdf-declarations/pdf-declarations.service';
import { OfficeLocation } from 'src/app/shared/office-location.enum';
import { DeclarationComplianceCheckDialogComponent } from 'src/app/pdf-declarations/declaration-compliance-check-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { PdfDeclarationRequest } from 'src/app/pdf-declarations/pdf-declaration-request.model';

@Component({
  selector: 'ad-uk-admin-dashboard',
  templateUrl: './uk-admin-dashboard.component.html',
  styleUrls: ['./uk-admin-dashboard.component.css']
})
export class UkAdminDashboardComponent implements OnInit {
  public displayedColumns: string[] = [
    'userName',
    'fitAndProperDeclarationStatus',
    'smcrStatus',
    'statementOfResponsibilitiesStatus',
    'ackedComplianceManual',
    'completionDate'
  ];
  public dataSource: MatTableDataSource<UKAdminDashboardItem>;
  public DeclarationStatus = DeclarationStatus;
  public DeclarationType = DeclarationType;

  public loading = true;

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private adminDashboardService: UkAdminDashboardService,
    private pdfDeclarationsService: PdfDeclarationsService,
    private checkDialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.loadAdminDashboardItems();
  }

  public requestToGeneratePdfDeclaration(declarationId: number, declarationType: DeclarationType): void {
    this.pdfDeclarationsService
      .generatePdfDeclaration$({
        declarationType,
        declarationId,
        officeLocation: OfficeLocation.London
      })
      .subscribe();
  }

  public showComplianceCheckDialog(declarationId: number, declarationType: DeclarationType) {
    const request: PdfDeclarationRequest = {
      declarationId,
      declarationType,
      officeLocation: OfficeLocation.London
    };

    const ackDialogRef = this.checkDialog.open(DeclarationComplianceCheckDialogComponent, {
      data: request,
      minWidth: '1000px'
    });

    ackDialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.pdfDeclarationsService
          .markDeclarationAsComplianceChecked$(request, data.name, data.date)
          .subscribe(() => this.loadAdminDashboardItems());
      }
    });
  }

  private loadAdminDashboardItems(): void {
    this.adminDashboardService.listAdminDashboardItems$().subscribe((items) => {
      this.dataSource = new MatTableDataSource(items);
      this.dataSource.sort = this.sort;
      this.loading = false;
    });
  }
}
