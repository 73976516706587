import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { DeclarationsAuthService } from 'src/app/auth/declarations-auth.service';
import { BasicUser } from 'src/app/core/basic-user.model';
import { UsersService } from 'src/app/core/users.service';
import { getAsFormGroup } from 'src/app/shared/utils';
import {
  UkStatementOfResponsibilities,
  UkStatementOfResponsibilitiesToAdd
} from './uk-statement-of-responsibilities.model';
import { UkStatementOfResponsibilitiesService } from './uk-statement-of-responsibilities.service';
import { MatDialog } from '@angular/material/dialog';
import { UkStatementOfResponsibilitiesAckDialogComponent } from './uk-statement-of-responsibilities-ack-dialog.component';

@Component({
  selector: 'ad-uk-statement-of-responsibilities',
  templateUrl: './uk-statement-of-responsibilities.component.html',
  styles: []
})
export class UkStatementOfResponsibilitiesComponent implements OnInit {
  public form: UntypedFormGroup;
  public declarationDate: Date;
  public user: BasicUser;
  public hasLastYearData = false;

  public onBehalfOf = false;

  public alreadySubmitted = false;

  public getAsFormGroup = getAsFormGroup;

  constructor(
    public ackDialog: MatDialog,
    private authService: DeclarationsAuthService,
    private fb: UntypedFormBuilder,
    private service: UkStatementOfResponsibilitiesService,
    private router: Router,
    private usersService: UsersService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      irn: [null],
      otherResponsibilities: this.fb.array([]),
      prescribedResponsibilities: this.fb.array([]),
      seniorManagementFunctions: this.fb.array([])
    });

    this.route.paramMap.subscribe((params) => {
      const email = params.get('email');

      if (email) {
        this.onBehalfOf = email !== this.authService.account.username;
        this.service.getOne$(email).subscribe((data) => {
          if (data) {
            this.populateInitialData(data);
          } else {
            this.usersService.getOne$(email).subscribe((user) => (this.user = user));
          }
        });
      } else {
        this.service.getMine$().subscribe((data) => {
          if (data) {
            this.populateInitialData(data);
          } else {
            this.service.getMineFromLastYear$().subscribe((lastYearData) => {
              this.hasLastYearData = !!lastYearData;
              this.populateInitialData(lastYearData);
            });
          }
          this.declarationDate = this.declarationDate || new Date();
        });
        this.usersService.getOne$(this.authService.account.username).subscribe((user) => (this.user = user));
      }
    });
  }

  public get seniorManagementFunctions(): UntypedFormArray {
    return this.form.get('seniorManagementFunctions') as UntypedFormArray;
  }

  public addSeniorManagementFunction(): void {
    this.seniorManagementFunctions.push(
      this.fb.group({
        description: ['', Validators.required],
        effectiveDate: [null, Validators.required],
        function: ['', Validators.required],
        typeOfFirm: ['', Validators.required]
      })
    );
  }

  public removeSeniorManagementFunction(index: number): void {
    this.seniorManagementFunctions.removeAt(index);
  }

  public get prescribedResponsibilities(): UntypedFormArray {
    return this.form.get('prescribedResponsibilities') as UntypedFormArray;
  }

  public addPrescribedResponsibility(): void {
    this.prescribedResponsibilities.push(
      this.fb.group({
        fcaRef: ['', Validators.required],
        isShared: [false, Validators.required],
        responsibility: ['', Validators.required],
        sharedJustification: [null, RxwebValidators.required({ conditionalExpression: (x, y) => x.isShared === true })]
      })
    );
  }

  public removePrescribedResponsibility(index: number): void {
    this.prescribedResponsibilities.removeAt(index);
  }

  public get otherResponsibilities(): UntypedFormArray {
    return this.form.get('otherResponsibilities') as UntypedFormArray;
  }

  public addOtherResponsibility(): void {
    this.otherResponsibilities.push(
      this.fb.group({
        details: ['', Validators.required],
        isShared: [false, Validators.required],
        sharedJustification: [null, RxwebValidators.required({ conditionalExpression: (x, y) => x.isShared === true })],
        title: ['', Validators.required]
      })
    );
  }

  public removeOtherResponsibility(index: number): void {
    this.otherResponsibilities.removeAt(index);
  }

  public submitForm(): void {
    const ackDialogRef = this.ackDialog.open(UkStatementOfResponsibilitiesAckDialogComponent, {
      data: {
        declarationDate: this.declarationDate
      },
      width: '800px'
    });

    ackDialogRef.afterClosed().subscribe((data) => {
      const value: UkStatementOfResponsibilitiesToAdd = {
        corporateTitle: this.user.title,
        declarationDate: data.declarationDate,
        employee: data.name,
        irn: this.form.get('irn').value,
        otherResponsibilities: this.otherResponsibilities.value,
        prescribedResponsibilities: this.prescribedResponsibilities.value,
        seniorManagementFunctions: this.seniorManagementFunctions.value,
        userEmail: this.user.email
      };

      this.form.reset();
      this.form.disable();

      this.service.acknowledge$(value).subscribe(() => this.router.navigate(['/']));
    });
  }

  public saveFormOnBehalOf(): void {
    const value: UkStatementOfResponsibilitiesToAdd = {
      corporateTitle: this.user.title,
      declarationDate: null,
      employee: this.user.name,
      irn: this.form.get('irn').value,
      otherResponsibilities: this.otherResponsibilities.value,
      prescribedResponsibilities: this.prescribedResponsibilities.value,
      seniorManagementFunctions: this.seniorManagementFunctions.value,
      userEmail: this.user.email
    };

    this.form.reset();
    this.form.disable();

    this.service.acknowledge$(value).subscribe(() => this.router.navigate(['/']));
  }

  private populateInitialData(data: UkStatementOfResponsibilities): void {
    if (data) {
      this.form.get('irn').setValue(data.irn);

      if (data.seniorManagementFunctions && data.seniorManagementFunctions.length > 0) {
        this.seniorManagementFunctions.clear();
        for (const seniorManagementFunction of data.seniorManagementFunctions) {
          this.seniorManagementFunctions.push(
            this.fb.group({
              description: [seniorManagementFunction.description, Validators.required],
              effectiveDate: [seniorManagementFunction.effectiveDate, Validators.required],
              function: [seniorManagementFunction.function, Validators.required],
              typeOfFirm: [seniorManagementFunction.typeOfFirm, Validators.required]
            })
          );
        }
      }

      if (data.prescribedResponsibilities && data.prescribedResponsibilities.length > 0) {
        this.prescribedResponsibilities.clear();
        for (const responsibility of data.prescribedResponsibilities) {
          this.prescribedResponsibilities.push(
            this.fb.group({
              fcaRef: [responsibility.fcaRef, Validators.required],
              isShared: [responsibility.isShared, Validators.required],
              responsibility: [responsibility.responsibility, Validators.required],
              sharedJustification: [
                responsibility.sharedJustification,
                RxwebValidators.required({ conditionalExpression: (x, y) => x.isShared === true })
              ]
            })
          );
        }
      }

      if (data.otherResponsibilities && data.otherResponsibilities.length > 0) {
        this.otherResponsibilities.clear();
        for (const responsibility of data.otherResponsibilities) {
          this.otherResponsibilities.push(
            this.fb.group({
              details: [responsibility.details, Validators.required],
              isShared: [responsibility.isShared, Validators.required],
              sharedJustification: [
                responsibility.sharedJustification,
                RxwebValidators.required({ conditionalExpression: (x, y) => x.isShared === true })
              ],
              title: [responsibility.title, Validators.required]
            })
          );
        }
      }

      if (!this.hasLastYearData) {
        this.declarationDate = data.declarationDate;
        this.user = {
          name: data.employee,
          email: data.userEmail,
          title: data.corporateTitle
        };
      }

      if (data.declarationDate) {
        this.alreadySubmitted = true;
        this.form.disable();
      }
    }
  }
}
