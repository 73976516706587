<mat-card>
  <mat-card-header>
    <mat-card-title> Employee External Business Interest Declaration Form </mat-card-title>
    <mat-card-subtitle>
      <p>
        To: Legal and Compliance Department Compagnie d'Investissements et de Gestion Priv&eacute;e (Hong Kong) Limited
        (&ldquo;CIGP HK&rdquo;)<br />
        Re: Employee External Business Interest Declaration
      </p>
    </mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <ad-alert-data-preloaded *ngIf="hasLastYearData"></ad-alert-data-preloaded>
    <form [formGroup]="form">
      <p>Please note that I, {{ authService.account.name }}, would like to notify CIGP HK on the following:</p>
      <mat-slide-toggle formControlName="hasExternalBusinessInterest">{{
        hasExternalBusinessInterestLabel
      }}</mat-slide-toggle>

      <ng-container formArrayName="businessInterests" *ngIf="hasExternalBusinessInterest">
        <div
          class="d-flex align-items-center"
          *ngFor="let businessInterest of businessInterests.controls; let i = index"
          [formGroup]="getAsFormGroup(businessInterest)"
        >
          <mat-card class="flex-grow-1">
            <mat-card-header>
              <mat-card-subtitle>Business Interest {{ i + 1 }}</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
              <mat-form-field>
                <mat-label>Name of organization and nature of business</mat-label>
                <textarea matInput formControlName="name" rows="3"></textarea>
                <mat-error *ngIf="businessInterest.get('name').hasError('required')"
                  >Name of organization and nature of business is required</mat-error
                >
              </mat-form-field>
              <mat-form-field>
                <mat-label>Role</mat-label>
                <mat-select formControlName="role">
                  <mat-option *ngFor="let role of roles" [value]="role">
                    {{ role }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="businessInterest.get('role').hasError('required')">Role is required</mat-error>
              </mat-form-field>
              <mat-form-field *ngIf="businessInterest.get('role').value === other">
                <input matInput placeholder="Please describe the role" formControlName="roleOther" />
                <mat-error *ngIf="businessInterest.get('roleOther').hasError('required')"
                  >Role description is required</mat-error
                >
              </mat-form-field>
              <mat-form-field>
                <mat-label>Date of commencement</mat-label>
                <input
                  matInput
                  [matDatepicker]="datePicker"
                  formControlName="dateOfCommencement"
                  placeholder="DD/MM/YYYY"
                />
                <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
                <mat-datepicker #datePicker></mat-datepicker>
                <mat-error *ngIf="businessInterest.get('dateOfCommencement').hasError('required')"
                  >Date of commencement is required</mat-error
                >
              </mat-form-field>
            </mat-card-content>
          </mat-card>
          <button
            mat-icon-button
            (click)="removeBusinessInterest(i)"
            *ngIf="businessInterests.length > 1"
            [disabled]="alreadySubmitted"
          >
            <mat-icon>remove_circle_outline</mat-icon>
          </button>
        </div>
        <div>
          <button
            mat-icon-button
            (click)="addBusinessInterest()"
            matTooltip="Add business interest"
            [disabled]="alreadySubmitted"
          >
            <mat-icon>add_circle_outline</mat-icon>
          </button>
        </div>
      </ng-container>

      <mat-form-field>
        <mat-label>Additional information (optional)</mat-label>
        <textarea matInput formControlName="additionalInformation" rows="4"></textarea>
      </mat-form-field>

      <ng-container *ngIf="alreadySubmitted">
        <p><strong>Acknowledgement and Confirmation by the Employee:</strong></p>
        <p>
          Name: {{ employee }}<br />
          Date: {{ declarationDate | date }}
        </p>
      </ng-container>
    </form>
  </mat-card-content>
  <mat-card-actions>
    <button *ngIf="alreadySubmitted; else canSubmitBlock" mat-button color="primary" disabled>
      YOU ALREADY SUBMITTED THIS DECLARATION
    </button>
    <ng-template #canSubmitBlock>
      <button mat-button color="primary" [disabled]="!form.valid" (click)="submitForm()">SUBMIT</button>
    </ng-template>
  </mat-card-actions>
</mat-card>
