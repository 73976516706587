export interface GVAFitAndProperDeclarationAdminDashboardContent {
  items: GVAFitAndProperDeclarationAdminDashboardItem[];
  userColumns: string[];
}

export interface GVAFitAndProperDeclarationAdminDashboardItem {
  dataType?: string;
  dtoProperty: string;
  questionLabel: string;
  questionSortIndex: number;
  sectionName: string;
  sectionSortIndex: number;
}

export const GVA_FIT_AND_PROPER_DECLARATION_ADMIN_DASHBOARD_QUESTIONS: GVAFitAndProperDeclarationAdminDashboardItem[] =
  [
    {
      dataType: 'date',
      dtoProperty: 'declarationDate',
      questionLabel: 'Date',
      questionSortIndex: 0,
      sectionName: 'Section 1: Personal Particulars',
      sectionSortIndex: 0
    },
    {
      dtoProperty: 'soFitNumber',
      questionLabel: 'SO-FIT Member #',
      questionSortIndex: 1,
      sectionName: 'Section 1: Personal Particulars',
      sectionSortIndex: 0
    },
    {
      dataType: 'boolean',
      dtoProperty: 'section2Question1',
      questionLabel: `
        I have read the &ldquo;<a
          href="http://so-fit.ch/documents/reglement-de-so-fit-relatif-aux-regles-cadres-pour-la-gestion-de-fortune/"
          target="_blank"
          >R&egrave;glement de so-fit relatif aux r&egrave;gles-cadres pour la gestion de fortune</a
        >&rdquo; of SO-FIT and understood its disclosing obligations, particularly those contained in its Chapter
        II.
      `,
      questionSortIndex: 0,
      sectionName: 'Section 2: General Information',
      sectionSortIndex: 1
    },
    {
      dataType: 'boolean',
      dtoProperty: 'section3Question1',
      questionLabel:
        'In the past or the present, have you as an individual; a director of a company; a management member of a company; or a substantial shareholder of a company ever been refused or restricted from the right to carry on any trade, business or profession for which a specific license, registration or other authorization is required by law; censured, disciplined, or disqualified by any professional or regulatory body (including a stock or futures exchange) in relation to any trade, business, or profession; or the subject of an investigation conducted by a regulatory or criminal investigatory body (i.e. disciplinary tribunal, examination authority, inspector appointed under any enactment, or other regulatory body)?',
      questionSortIndex: 0,
      sectionName: 'Section 3: Disciplinary Actions and Investigations',
      sectionSortIndex: 2
    },
    {
      dataType: 'boolean',
      dtoProperty: 'section3Question2',
      questionLabel:
        'Are there any disciplinary actions or proceedings pending against you, as an individual; a past or current director of a company; a past or current management member of a company; or< a past or current substantial shareholder of a company, in relation to any trade, business, or profession?',
      questionSortIndex: 1,
      sectionName: 'Section 3: Disciplinary Actions and Investigations',
      sectionSortIndex: 2
    },
    {
      dataType: 'boolean',
      dtoProperty: 'section3Question3',
      questionLabel:
        'In the past or the present, have you as: an individual; a director of a company; a management member of a company; or a substantial shareholder of a company ever been investigated about offences involving fraud or dishonesty; or adjudged or found by a court to be civilly liable for fraud, dishonesty, or misfeasance; or any other economic crime?',
      questionSortIndex: 2,
      sectionName: 'Section 3: Disciplinary Actions and Investigations',
      sectionSortIndex: 2
    },
    {
      dataType: 'boolean',
      dtoProperty: 'section3Question4',
      questionLabel:
        'Have you ever been disqualified by a court from being a director of a corporation, or the equivalent in another jurisdiction?',
      questionSortIndex: 3,
      sectionName: 'Section 3: Disciplinary Actions and Investigations',
      sectionSortIndex: 2
    },
    {
      dataType: 'boolean',
      dtoProperty: 'section3Question5',
      questionLabel:
        'Have you ever been charged with a criminal offence other than a minor traffic or littering offence?',
      questionSortIndex: 4,
      sectionName: 'Section 3: Disciplinary Actions and Investigations',
      sectionSortIndex: 2
    },
    {
      dataType: 'boolean',
      dtoProperty: 'section3Question6',
      questionLabel:
        'Have you ever been convicted of a criminal offence other than a minor traffic or littering offence?',
      questionSortIndex: 5,
      sectionName: 'Section 3: Disciplinary Actions and Investigations',
      sectionSortIndex: 2
    },
    {
      dataType: 'boolean',
      dtoProperty: 'section3Question7',
      questionLabel: 'Are you presently party to any civil litigation?',
      questionSortIndex: 6,
      sectionName: 'Section 3: Disciplinary Actions and Investigations',
      sectionSortIndex: 2
    },
    {
      dataType: 'boolean',
      dtoProperty: 'section3Question8',
      questionLabel: 'Are you in breach of any judgements or court orders?',
      questionSortIndex: 7,
      sectionName: 'Section 3: Disciplinary Actions and Investigations',
      sectionSortIndex: 2
    },
    {
      dataType: 'boolean',
      dtoProperty: 'section31Question1',
      questionLabel: 'Do you have a power of attorney in your name on behalf of clients?',
      questionSortIndex: 0,
      sectionName: 'Section 3.1: Identification of conflict of interests',
      sectionSortIndex: 3
    },
    {
      dataType: 'boolean',
      dtoProperty: 'section31Question2',
      questionLabel: 'Does your activity within CIGP enable you to benefit of retrocessions from third-parties?',
      questionSortIndex: 1,
      sectionName: 'Section 3.1: Identification of conflict of interests',
      sectionSortIndex: 3
    },
    {
      dataType: 'boolean',
      dtoProperty: 'section31Question3',
      questionLabel: 'Are you trading in financial instruments for your own account with third parties?',
      questionSortIndex: 2,
      sectionName: 'Section 3.1: Identification of conflict of interests',
      sectionSortIndex: 3
    },
    {
      dataType: 'boolean',
      dtoProperty: 'section31Question4',
      questionLabel: 'Have you ever received a political mandate?',
      questionSortIndex: 3,
      sectionName: 'Section 3.1: Identification of conflict of interests',
      sectionSortIndex: 3
    },
    {
      dataType: 'boolean',
      dtoProperty: 'section31Question5',
      questionLabel: 'Have you received any gifts (vacation trip, invitation, etc.) in an amount exceeding CHF 250.00?',
      questionSortIndex: 4,
      sectionName: 'Section 3.1: Identification of conflict of interests',
      sectionSortIndex: 3
    },
    {
      dataType: 'boolean',
      dtoProperty: 'section4Question1',
      questionLabel:
        'Have you ever been party to any civil litigation in the past ten (10) years involving an amount in excess of CHF 10,000 or equivalent?',
      questionSortIndex: 0,
      sectionName: 'Section 4: Financial Status',
      sectionSortIndex: 4
    },
    {
      dataType: 'boolean',
      dtoProperty: 'section4Question2',
      questionLabel:
        'Have you ever been a party to a scheme of arrangement; or entered into any form of compromise with your creditor, in the past ten (10) years involving an amount in excess of CHF 10,000 or equivalent?',
      questionSortIndex: 1,
      sectionName: 'Section 4: Financial Status',
      sectionSortIndex: 4
    },
    {
      dataType: 'boolean',
      dtoProperty: 'section4Question3',
      questionLabel: 'Have you ever been bankrupt, or been served with a bankruptcy petition?',
      questionSortIndex: 2,
      sectionName: 'Section 4: Financial Status',
      sectionSortIndex: 4
    },
    {
      dataType: 'boolean',
      dtoProperty: 'section4Question4',
      questionLabel:
        'Have you ever been a director, substantial shareholder, or involved in the management of a corporation which was wound up other than by a members’ voluntary wind up?',
      questionSortIndex: 3,
      sectionName: 'Section 4: Financial Status',
      sectionSortIndex: 4
    },
    {
      dataType: 'boolean',
      dtoProperty: 'section4Question5',
      questionLabel:
        'Have you ever been a partner of a firm which was dissolved other than with the consent of all the partners?',
      questionSortIndex: 4,
      sectionName: 'Section 4: Financial Status',
      sectionSortIndex: 4
    },
    {
      dataType: 'boolean',
      dtoProperty: 'section5Question1',
      questionLabel:
        'Have you ever been professionally diagnosed as mentally incapacitated, defined as a person who is incapable, by reason of mental incapacity, of managing and administering his property & affairs?',
      questionSortIndex: 0,
      sectionName: 'Section 5: Mental Health',
      sectionSortIndex: 5
    },
    {
      dtoProperty: 'additionalInformation',
      questionLabel: 'Additional Information',
      questionSortIndex: 0,
      sectionName: 'Section 6: Additional Information',
      sectionSortIndex: 6
    }
  ];
