import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { showErrorDialog } from 'src/app/shared/utils';
import { environment } from 'src/environments/environment';
import { UKAdminDashboardItem } from './uk-admin-dashboard-item.model';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class UkAdminDashboardService {
  private apiEndpoint = `${environment.apiEndpoint}/api/uk/admin-dashboard`;

  constructor(private http: HttpClient, private logger: NGXLogger, private apiErrorDialog: MatDialog) {}

  listAdminDashboardItems$(): Observable<UKAdminDashboardItem[]> {
    return this.http.get<UKAdminDashboardItem[]>(this.apiEndpoint).pipe(
      catchError((error) => {
        this.logger.error(error);
        showErrorDialog(this.apiErrorDialog, error);
        return of([]);
      })
    );
  }
}
