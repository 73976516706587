import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DeclarationsAuthService } from '../auth/declarations-auth.service';

@Component({
  selector: 'ad-landing',
  template: ` <div class="d-flex gap-3 align-items-center">Loading ...</div> `
})
export class LandingComponent implements OnInit {
  constructor(
    private declarationsAuthService: DeclarationsAuthService,
    private router: Router
  ) {}

  public ngOnInit(): void {
    this.declarationsAuthService.loggedIn$.subscribe((loggedIn) => {
      if (loggedIn) {
        this.router.navigate(['/home']);
      }
    });
  }
}
