import { Component, OnInit, ViewChild } from '@angular/core';
import { GridComponent, GroupSettingsModel } from '@syncfusion/ej2-angular-grids';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { GVAFitAndProperDeclarationAdminDashboardContent } from './gva-fit-and-proper-declaration-admin-dashboard.model';
import { GVAFitAndProperDeclarationService } from './gva-fit-and-proper-declaration.service';

@Component({
  selector: 'ad-gva-fit-and-proper-declaration-admin-dashboard',
  template: `
    <mat-card *ngIf="content$ | async as content">
      <ejs-grid
        #grid
        id="grid"
        [dataSource]="content.items"
        [allowGrouping]="true"
        [groupSettings]="groupOptions"
        [allowTextWrap]="true"
      >
        <e-columns>
          <e-column field="sectionName"></e-column>
          <e-column field="questionLabel" headerText="">
            <ng-template #template let-data>
              <div [innerHtml]="data.questionLabel"></div>
            </ng-template>
          </e-column>
          <e-column *ngFor="let user of content.userColumns" [field]="user"></e-column>
        </e-columns>
        <ng-template #groupSettingsCaptionTemplate let-data>
          {{ data.key }}
        </ng-template>
      </ejs-grid>
    </mat-card>
  `,
  styles: ['mat-card { padding: 0 }']
})
export class GvaFitAndProperDeclarationAdminDashboardComponent implements OnInit {
  public content$: Observable<GVAFitAndProperDeclarationAdminDashboardContent>;
  public groupOptions: GroupSettingsModel;

  @ViewChild('grid')
  public grid: GridComponent;

  constructor(private service: GVAFitAndProperDeclarationService) {}

  ngOnInit(): void {
    this.content$ = this.service.getManyForAdminDashboard$().pipe(
      tap(() => {
        this.groupOptions = { showDropArea: false, columns: ['sectionName'] };
      })
    );
  }
}
