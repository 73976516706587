import { Component, Inject } from '@angular/core';
import { DeclarationsAuthService } from '../auth/declarations-auth.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TitleCasePipe } from '@angular/common';
import { valuesMatch } from '../shared/name-must-match.directive';

@Component({
    selector: 'ad-external-business-interest-declaration-ack-dialog',
    template: `
    <h1 mat-dialog-title>ACKNOWLEDGMENT</h1>
    <div mat-dialog-content>
      <p>Acknowledgement and Confirmation by the Employee</p>
      <mat-form-field>
        <input
          matInput
          placeholder="Please input your full name"
          [(ngModel)]="data.name"
          adBlockCopyPaste
          [adNameMustMatch]="authService.account.name"
        />
        <mat-hint>Must match your full name in the corporate directory</mat-hint>
      </mat-form-field>
      <p>
        <strong>{{ data.declarationDate | date }}</strong>
      </p>
    </div>
    <div mat-dialog-actions>
      <button mat-button (click)="closeDialog()" [disabled]="!isValid()">SUBMIT</button>
    </div>
  `,
    providers: [TitleCasePipe],
    standalone: false
})
export class ExternalBusinessInterestDeclarationAckDialogComponent {
  constructor(
    public authService: DeclarationsAuthService,
    public dialogRef: MatDialogRef<ExternalBusinessInterestDeclarationAckDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private titleCasePipe: TitleCasePipe
  ) {}

  public isValid(): boolean {
    return valuesMatch(this.data.name, this.authService.account.name);
  }

  public closeDialog() {
    this.data.name = this.titleCasePipe.transform(this.data.name);
    this.dialogRef.close(this.data);
  }
}
