import { Component, OnInit } from '@angular/core';
import { DeclarationsAuthService } from '../auth/declarations-auth.service';
import { ComplianceManualService } from '../compliance-manual/compliance-manual.service';
import { TitleService } from '../core/title.service';
import { EmployeeHandbookService } from '../employee-handbook/employee-handbook.service';
import { EthicStandardsAndCorporateCultureService } from '../ethic-standards-and-corporate-culture/ethic-standards-and-corporate-culture.service';
// tslint:disable-next-line: max-line-length
import { ExternalBusinessInterestDeclarationService } from '../external-business-interest-declaration/external-business-interest-declaration.service';
import { FitAndProperDeclarationService } from '../fit-and-proper-declaration/fit-and-proper-declaration.service';
import { GVAFitAndProperDeclarationService } from '../geneva/gva-fit-and-proper-declaration/gva-fit-and-proper-declaration.service';
import { PersonalAccountDealingDeclarationService } from '../personal-account-dealing-declaration/personal-account-dealing-declaration.service';
import { PersonalAccountDealingPolicyService } from '../personal-account-dealing-policy/personal-account-dealing-policy.service';
import { ROLE_GVA_USER, ROLE_HK_USER, ROLE_UK_USER } from '../shared/constants';
import { UkComplianceManualService } from '../uk/uk-compliance-manual/uk-compliance-manual.service';
import { UkFitAndProperDeclarationService } from '../uk/uk-fit-and-proper-declaration/uk-fit-and-proper-declaration.service';
import { UkSmcrService } from '../uk/uk-smcr/uk-smcr.service';
import { UkStatementOfResponsibilitiesService } from '../uk/uk-statement-of-responsibilities/uk-statement-of-responsibilities.service';
import { HkDrBcpAcksService } from '../hk/dr-bcp-acks/dr-bcp-acks.service';

@Component({
  selector: 'ad-home',
  template: `
    <mat-card>
      <!-- GVA documents and declarations -->
      <ad-gva-user>
        <mat-nav-list>
          <h4 matSubheader>GENEVA</h4>
          <mat-list-item>
            <div class="d-flex justify-content-between align-items-center">
              <a matLine [routerLink]="['/gva', 'fit-and-proper-declaration']"
                >Employee Fit &amp; Proper Declaration
              </a>
              <mat-icon
                [ngStyle]="{
                  color: gvaFitAndProperDeclarationSubmitted ? 'rgba(0, 128, 0, 0.87)' : 'rgba(255, 0, 0, 0.87)'
                }"
                >{{ gvaFitAndProperDeclarationSubmitted ? 'check' : 'info' }}</mat-icon
              >
            </div>
          </mat-list-item>
        </mat-nav-list>
      </ad-gva-user>

      <!-- HK documents and declarations -->
      <ad-hk-user>
        <mat-nav-list>
          <h4 matSubheader>HONG KONG</h4>
          <mat-list-item>
            <div class="d-flex justify-content-between align-items-center">
              <a matLine [routerLink]="['/compliance-manual']">Compliance Manual</a>
              <mat-icon
                [ngStyle]="{ color: complianceManualAcked ? 'rgba(0, 128, 0, 0.87)' : 'rgba(255, 0, 0, 0.87)' }"
                >{{ complianceManualAcked ? 'check' : 'info' }}</mat-icon
              >
            </div>
          </mat-list-item>
          <mat-list-item>
            <div class="d-flex justify-content-between align-items-center">
              <a matLine [routerLink]="['/hk/dr-bcp-plan']">DR/BCP Plan</a>
              <mat-icon [ngStyle]="{ color: hkDrBcpPlanAcked ? 'rgba(0, 128, 0, 0.87)' : 'rgba(255, 0, 0, 0.87)' }">{{
                hkDrBcpPlanAcked ? 'check' : 'info'
              }}</mat-icon>
            </div>
          </mat-list-item>
          <mat-list-item>
            <div class="d-flex justify-content-between align-items-center">
              <a matLine [routerLink]="['/employee-handbook']">Employee Handbook</a>
              <mat-icon
                [ngStyle]="{ color: employeeHandbookAcked ? 'rgba(0, 128, 0, 0.87)' : 'rgba(255, 0, 0, 0.87)' }"
                >{{ employeeHandbookAcked ? 'check' : 'info' }}</mat-icon
              >
            </div>
          </mat-list-item>
          <mat-list-item>
            <div class="d-flex justify-content-between align-items-center">
              <a matLine [routerLink]="['/ethic-standards-and-corporate-culture']"
                >Ethic Standards &amp; Corporate Culture</a
              >
              <mat-icon
                [ngStyle]="{
                  color: ethicStandardsAndCorporateCultureManualAcked
                    ? 'rgba(0, 128, 0, 0.87)'
                    : 'rgba(255, 0, 0, 0.87)'
                }"
                >{{ ethicStandardsAndCorporateCultureManualAcked ? 'check' : 'info' }}</mat-icon
              >
            </div>
          </mat-list-item>
          <mat-list-item>
            <div class="d-flex justify-content-between align-items-center">
              <a matLine [routerLink]="['/external-business-interest-declaration']"
                >Employee External Business Interest Declaration</a
              >
              <mat-icon
                [ngStyle]="{
                  color: externalBusinessInterestDeclarationSubmitted
                    ? 'rgba(0, 128, 0, 0.87)'
                    : 'rgba(255, 0, 0, 0.87)'
                }"
                >{{ externalBusinessInterestDeclarationSubmitted ? 'check' : 'info' }}</mat-icon
              >
            </div>
          </mat-list-item>
          <mat-list-item>
            <div class="d-flex justify-content-between align-items-center">
              <a matLine [routerLink]="['/fit-and-proper-declaration']">Employee Fit &amp; Proper Declaration</a>
              <mat-icon
                [ngStyle]="{
                  color: fitAndProperDeclarationSubmitted ? 'rgba(0, 128, 0, 0.87)' : 'rgba(255, 0, 0, 0.87)'
                }"
                >{{ fitAndProperDeclarationSubmitted ? 'check' : 'info' }}</mat-icon
              >
            </div>
          </mat-list-item>
          <mat-list-item>
            <div class="d-flex justify-content-between align-items-center">
              <a matLine [routerLink]="['/personal-account-dealing-policy']"
                >Employee Personal Account Dealing Policy</a
              >
              <mat-icon
                [ngStyle]="{
                  color: personalAccountDealingPolicyAcked ? 'rgba(0, 128, 0, 0.87)' : 'rgba(255, 0, 0, 0.87)'
                }"
                >{{ personalAccountDealingPolicyAcked ? 'check' : 'info' }}</mat-icon
              >
            </div>
          </mat-list-item>
          <mat-list-item>
            <div class="d-flex justify-content-between align-items-center">
              <a matLine [routerLink]="['/personal-account-dealing-declaration']"
                >Employee Personal Account Dealing Declaration</a
              >
              <mat-icon
                [ngStyle]="{
                  color: personalAccountDealingDeclarationSubmitted ? 'rgba(0, 128, 0, 0.87)' : 'rgba(255, 0, 0, 0.87)'
                }"
                >{{ personalAccountDealingDeclarationSubmitted ? 'check' : 'info' }}</mat-icon
              >
            </div>
          </mat-list-item>
        </mat-nav-list>
      </ad-hk-user>

      <!-- UK documents and declarations -->
      <ad-uk-user>
        <mat-nav-list>
          <h4 matSubheader>UK</h4>
          <mat-list-item>
            <div class="d-flex justify-content-between align-items-center">
              <a matLine [routerLink]="['/uk', 'compliance-manual']">Compliance Manual</a>
              <mat-icon
                [ngStyle]="{
                  color: ukComplianceManualAcked ? 'rgba(0, 128, 0, 0.87)' : 'rgba(255, 0, 0, 0.87)'
                }"
                >{{ ukComplianceManualAcked ? 'check' : 'info' }}</mat-icon
              >
            </div>
          </mat-list-item>
          <mat-list-item>
            <div class="d-flex justify-content-between align-items-center">
              <a matLine [routerLink]="['/uk', 'fit-and-proper-declaration']">Employee Fit &amp; Proper Declaration</a>
              <mat-icon
                [ngStyle]="{
                  color: ukFitAndProperDeclarationSubmitted ? 'rgba(0, 128, 0, 0.87)' : 'rgba(255, 0, 0, 0.87)'
                }"
                >{{ ukFitAndProperDeclarationSubmitted ? 'check' : 'info' }}</mat-icon
              >
            </div>
          </mat-list-item>
          <mat-list-item>
            <div class="d-flex justify-content-between align-items-center">
              <a matLine [routerLink]="['/uk', 'smcr']">Senior Managers & Certification Regime</a>
              <mat-icon
                [ngStyle]="{
                  color: ukSmcrSubmitted ? 'rgba(0, 128, 0, 0.87)' : 'rgba(255, 0, 0, 0.87)'
                }"
                >{{ ukSmcrSubmitted ? 'check' : 'info' }}</mat-icon
              >
            </div>
          </mat-list-item>
          <mat-list-item>
            <div class="d-flex justify-content-between align-items-center">
              <a matLine [routerLink]="['/uk', 'statement-of-responsibilities']">Statement of Responsibilities</a>
              <mat-icon
                [ngStyle]="{
                  color: ukStatementOfResponsibilitiesSubmitted ? 'rgba(0, 128, 0, 0.87)' : 'rgba(255, 0, 0, 0.87)'
                }"
                >{{ ukStatementOfResponsibilitiesSubmitted ? 'check' : 'info' }}</mat-icon
              >
            </div>
          </mat-list-item>
        </mat-nav-list>
      </ad-uk-user>

      <ad-authorized>
        <mat-nav-list>
          <h4 matSubheader>ADMIN</h4>
          <mat-list-item>
            <a matLine [routerLink]="['/admin-dashboard']">Submissions Dashboard</a>
          </mat-list-item>
        </mat-nav-list>
      </ad-authorized>
    </mat-card>
  `,
  styles: [':host { height: 100%; }', 'h3 { margin: 0 1rem; }']
})
export class HomeComponent implements OnInit {
  public complianceManualAcked: boolean;
  public hkDrBcpPlanAcked: boolean;
  public employeeHandbookAcked: boolean;
  public ethicStandardsAndCorporateCultureManualAcked: boolean;
  public externalBusinessInterestDeclarationSubmitted: boolean;
  public fitAndProperDeclarationSubmitted: boolean;
  public personalAccountDealingDeclarationSubmitted: boolean;
  public personalAccountDealingPolicyAcked: boolean;
  public gvaFitAndProperDeclarationSubmitted: boolean;
  public ukComplianceManualAcked: boolean;
  public ukFitAndProperDeclarationSubmitted: boolean;
  public ukSmcrSubmitted: boolean;
  public ukStatementOfResponsibilitiesSubmitted: boolean;

  constructor(
    titleService: TitleService,
    private complianceManualService: ComplianceManualService,
    private employeeHandbookService: EmployeeHandbookService,
    private ethicStandardsAndCorporateCultureService: EthicStandardsAndCorporateCultureService,
    private externalBusinessInterestDeclarationService: ExternalBusinessInterestDeclarationService,
    private fitAndProperDeclarationService: FitAndProperDeclarationService,
    private hkDrBcpAcksService: HkDrBcpAcksService,
    private personalAccountDealingDeclarationService: PersonalAccountDealingDeclarationService,
    private personalAccountDealingPolicyService: PersonalAccountDealingPolicyService,
    private gvaFitAndProperDeclarationService: GVAFitAndProperDeclarationService,
    private ukComplianceManualService: UkComplianceManualService,
    private ukFitAndProperDeclarationService: UkFitAndProperDeclarationService,
    private ukSmcrService: UkSmcrService,
    private ukStatementOfResponsibilitiesService: UkStatementOfResponsibilitiesService,
    private authService: DeclarationsAuthService
  ) {
    titleService.title = '';
  }

  ngOnInit(): void {
    if (this.authService.hasRole(ROLE_HK_USER)) {
      this.subscribeHK();
    }

    if (this.authService.hasRole(ROLE_GVA_USER)) {
      this.subscribeGVA();
    }

    if (this.authService.hasRole(ROLE_UK_USER)) {
      this.subscribeUK();
    }
  }

  private subscribeHK(): void {
    this.complianceManualService.getMine$().subscribe((item) => (this.complianceManualAcked = !!item));
    this.employeeHandbookService.getMine$().subscribe((item) => (this.employeeHandbookAcked = !!item));
    this.ethicStandardsAndCorporateCultureService
      .getMine$()
      .subscribe((item) => (this.ethicStandardsAndCorporateCultureManualAcked = !!item));
    this.externalBusinessInterestDeclarationService
      .getMine$()
      .subscribe((item) => (this.externalBusinessInterestDeclarationSubmitted = !!item));
    this.fitAndProperDeclarationService
      .getMine$()
      .subscribe((item) => (this.fitAndProperDeclarationSubmitted = !!item));
    this.hkDrBcpAcksService.getMine$().subscribe((item) => (this.hkDrBcpPlanAcked = !!item));
    this.personalAccountDealingDeclarationService
      .getMine$()
      .subscribe((item) => (this.personalAccountDealingDeclarationSubmitted = !!item));
    this.personalAccountDealingPolicyService
      .getMine$()
      .subscribe((item) => (this.personalAccountDealingPolicyAcked = !!item));
  }

  private subscribeGVA(): void {
    this.gvaFitAndProperDeclarationService
      .getMine$()
      .subscribe((item) => (this.gvaFitAndProperDeclarationSubmitted = !!item));
  }

  private subscribeUK(): void {
    this.ukComplianceManualService.getMine$().subscribe((item) => (this.ukComplianceManualAcked = !!item));
    this.ukFitAndProperDeclarationService
      .getMine$()
      .subscribe((item) => (this.ukFitAndProperDeclarationSubmitted = !!item?.declarationDate));
    this.ukSmcrService.getMine$().subscribe((item) => (this.ukSmcrSubmitted = !!item?.declarationDate));
    this.ukStatementOfResponsibilitiesService
      .getMine$()
      .subscribe((item) => (this.ukStatementOfResponsibilitiesSubmitted = !!item?.declarationDate));
  }
}
