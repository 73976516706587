<table mat-table [dataSource]="dataSource" matSort>
  <!-- User Name -->
  <ng-container matColumnDef="userName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header style="cursor: pointer">User</th>
    <td mat-cell *matCellDef="let element" style="text-align: left">{{ element.userName }}</td>
  </ng-container>

  <!-- Prev Fit & Proper Decl -->
  <ng-container matColumnDef="prevFitAndProperDeclaration">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Fit &amp; Proper Decl ({{ prevDisplayDate }})</th>
    <td mat-cell *matCellDef="let element">
      <ng-container [ngSwitch]="element.joinedAfterCurDecl$ | async">
        <ng-container *ngSwitchCase="true">
          <ng-container *ngTemplateOutlet="naBlock"></ng-container>
        </ng-container>

        <ng-container *ngSwitchDefault>
          <div
            class="d-flex justify-content-center align-items-center"
            *ngIf="element.fitAndProperDeclaration.submittedPrev; else noBlock"
          >
            <a
              [routerLink]="[
                '/gva',
                'fit-and-proper-declaration',
                element.userEmail,
                element.prevYear,
                element.prevMonth
              ]"
            >
              <mat-icon class="text-success" [title]="element.fitAndProperDeclaration.curCompletionDate | date"
                >check</mat-icon
              >
            </a>
            <button
              class="btn btn-sm"
              (click)="
                requestToGeneratePdfDeclaration(
                  element.fitAndProperDeclaration.prevId,
                  DeclarationType.EmployeeFitAndProperDeclaration
                )
              "
            >
              <i class="bi bi-file-earmark-pdf" title="Regenerate PDF declaration"></i>
            </button>
          </div>
        </ng-container>
      </ng-container></td
  ></ng-container>

  <!-- Cur Fit & Proper Decl -->
  <ng-container matColumnDef="curFitAndProperDeclaration">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Fit &amp; Proper Decl ({{ curDisplayDate }})</th>
    <td mat-cell *matCellDef="let element">
      <div
        class="d-flex justify-content-center align-items-center"
        *ngIf="element.fitAndProperDeclaration.submittedCur; else noBlock"
      >
        <a [routerLink]="['/gva', 'fit-and-proper-declaration', element.userEmail, element.curYear, element.curMonth]">
          <mat-icon class="text-success" [title]="element.fitAndProperDeclaration.curCompletionDate | date"
            >check</mat-icon
          >
        </a>
        <button
          class="btn btn-sm"
          (click)="
            requestToGeneratePdfDeclaration(
              element.fitAndProperDeclaration.curId,
              DeclarationType.EmployeeFitAndProperDeclaration
            )
          "
        >
          <i class="bi bi-file-earmark-pdf" title="Regenerate PDF declaration"></i>
        </button>
      </div></td
  ></ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let element; columns: displayedColumns" class="element-row"></tr>

  <ng-template #noBlock>
    <mat-icon style="color: rgba(255, 0, 0, 0.87)">close</mat-icon>
  </ng-template>

  <ng-template #naBlock> N/A </ng-template>
</table>
<mat-spinner *ngIf="loading"></mat-spinner>
