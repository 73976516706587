import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { initials, showErrorDialog, showSuccessSnackbar, yesNo } from '../../shared/utils';
import {
  GVAFitAndProperDeclarationAdminDashboardContent,
  GVAFitAndProperDeclarationAdminDashboardItem,
  GVA_FIT_AND_PROPER_DECLARATION_ADMIN_DASHBOARD_QUESTIONS
} from './gva-fit-and-proper-declaration-admin-dashboard.model';
import { GVAFitAndProperDeclaration, GVAFitAndProperDeclarationToAdd } from './gva-fit-and-proper-declaration.model';
import { orderBy, sortedUniq } from 'lodash-es';
import moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class GVAFitAndProperDeclarationService {
  private apiEndpoint = `${environment.apiEndpoint}/api/gva/employee-fit-and-proper-declaration`;

  constructor(
    private http: HttpClient,
    private logger: NGXLogger,
    public apiErrorDialog: MatDialog,
    private snackBar: MatSnackBar
  ) {}

  public getMine$(): Observable<GVAFitAndProperDeclaration | null> {
    return this.http.get<GVAFitAndProperDeclaration>(`${this.apiEndpoint}/me`).pipe(catchError(() => of(null)));
  }

  public getMineFromPreviousPeriod$(): Observable<GVAFitAndProperDeclaration | null> {
    return this.http
      .get<GVAFitAndProperDeclaration>(`${this.apiEndpoint}/me/previous`)
      .pipe(catchError(() => of(null)));
  }

  public getOne$(email: string, year: number, month: number): Observable<GVAFitAndProperDeclaration> {
    return this.http
      .get<GVAFitAndProperDeclaration>(`${this.apiEndpoint}/${email}/${year}/${month}`)
      .pipe(catchError(() => of(null)));
  }

  acknowledge$(value: GVAFitAndProperDeclarationToAdd): Observable<void> {
    return this.http.post(this.apiEndpoint, value).pipe(
      map(() => showSuccessSnackbar(this.snackBar, 'Your declaration was successfully recorded')),
      catchError((error: any) => {
        this.logger.error(error);
        showErrorDialog(this.apiErrorDialog, error);
        return throwError(() => new Error(error));
      })
    );
  }

  public getManyForAdminDashboard$(): Observable<GVAFitAndProperDeclarationAdminDashboardContent> {
    return this.http.get<GVAFitAndProperDeclaration[]>(this.apiEndpoint).pipe(
      map((declarations) => {
        const userColumns: string[] = sortedUniq(orderBy(declarations.map((d) => initials(d.employee))));

        const items: GVAFitAndProperDeclarationAdminDashboardItem[] = orderBy(
          GVA_FIT_AND_PROPER_DECLARATION_ADMIN_DASHBOARD_QUESTIONS.map((question) => {
            for (const declaration of declarations) {
              for (const kvp of Object.entries(declaration)) {
                if (kvp[0] === question.dtoProperty) {
                  question[initials(declaration.employee)] = this.formatResponse(kvp[1], question.dataType);
                }
              }
            }
            return question;
          }),
          ['sectionSortIndex', 'questionSortIndex']
        );

        return {
          items,
          userColumns
        };
      }),
      catchError(() => of(null))
    );
  }

  private formatResponse(response: any, dataType: string): string {
    if (dataType === 'date') {
      return moment(response).format('YYYY-MM-DD');
    }

    if (dataType === 'boolean') {
      return yesNo(response);
    }

    return response;
  }
}
