import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { Observable } from 'rxjs';
import { DeclarationsAuthService } from 'src/app/auth/declarations-auth.service';
import { BasicUser } from 'src/app/core/basic-user.model';
import { UsersService } from 'src/app/core/users.service';
import { UkSMCR, UkSMCRToAdd } from './uk-smcr.model';
import { UkSmcrService } from './uk-smcr.service';
import { UkSmcrAckDialogComponent } from './uk-smcr-ack-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'ad-uk-smcr',
  templateUrl: './uk-smcr.component.html',
  styleUrls: ['./uk-smcr.component.css']
})
export class UkSmcrComponent implements OnInit {
  public form: UntypedFormGroup;
  public declarationDate: Date;
  public hasLastYearData = false;
  public alreadySubmitted = false;
  public user: BasicUser;

  public s2Q1HeldFunctionsOptions$: Observable<string[]>;
  public s2Q1PrescribedResponsibilitiesOptions$: Observable<string[]>;
  public s2Q2HeldCertificationFunctionsOptions$: Observable<string[]>;

  public onBehalfOf = false;

  constructor(
    public ackDialog: MatDialog,
    public authService: DeclarationsAuthService,
    private fb: UntypedFormBuilder,
    private usersService: UsersService,
    private service: UkSmcrService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.s2Q1HeldFunctionsOptions$ = this.service.getS2Q1FunctionOptions$();
    this.s2Q1PrescribedResponsibilitiesOptions$ = this.service.getS2Q1PrescribedResponsibilitiesOptions$();
    this.s2Q2HeldCertificationFunctionsOptions$ = this.service.getS2Q2Options$();

    this.form = this.fb.group({
      irn: [null],
      s2Q1: [null, Validators.required],
      s2Q1HeldFunctions: [
        [],
        RxwebValidators.required({
          conditionalExpression: (x) => x.s2Q1 === true
        })
      ],
      s2Q1PrescribedResponsibilities: [[]],
      s2Q2: [null, Validators.required],
      s2Q2HeldCertificationFunctions: [
        [],
        RxwebValidators.required({
          conditionalExpression: (x) => x.s2Q2 === true
        })
      ],
      s2Q3a: ['', Validators.required],
      s2Q3b: ['', Validators.required],
      s2Q4: ['', Validators.required],
      s2Q5: ['', Validators.required],
      s2Q6: ['', Validators.required],
      s2Q7a: ['', Validators.required],
      s2Q7b: ['', Validators.required],
      s2Q8a: ['', Validators.required],
      s2Q8b: ['', Validators.required]
    });

    this.route.paramMap.subscribe((params) => {
      const email = params.get('email');

      if (email) {
        this.onBehalfOf = email !== this.authService.account.username;
        this.service.getOne$(email).subscribe((data) => {
          if (data) {
            this.populateInitialData(data);
          } else {
            this.usersService.getOne$(email).subscribe((user) => (this.user = user));
          }
        });
      } else {
        this.service.getMine$().subscribe((data) => {
          if (data) {
            this.populateInitialData(data);
          } else {
            this.service.getMineFromLastYear$().subscribe((lastYearData) => {
              this.hasLastYearData = !!lastYearData;
              this.populateInitialData(lastYearData);
            });
          }
          this.declarationDate = this.declarationDate || new Date();
        });
        this.usersService.getOne$(this.authService.account.username).subscribe((user) => (this.user = user));
      }
    });

    this.form.get('s2Q1')?.valueChanges.subscribe((value) => {
      if (!value) {
        this.form.get('s2Q1HeldFunctions').setValue([]);
        this.form.get('s2Q1PrescribedResponsibilities').setValue([]);
      }
    });

    this.form.get('s2Q2')?.valueChanges.subscribe((value) => {
      if (!value) {
        this.form.get('s2Q2HeldCertificationFunctions').setValue([]);
      }
    });
  }

  public saveOrSubmitForm(): void {
    if (this.onBehalfOf) {
      this.saveFormOnBehalfOf();
    } else {
      this.submitFormAsTheUser();
    }
  }

  private submitFormAsTheUser(): void {
    const ackDialogRef = this.ackDialog.open(UkSmcrAckDialogComponent, {
      data: {
        declarationDate: this.declarationDate
      },
      width: '800px'
    });

    ackDialogRef.afterClosed().subscribe((data) => {
      const value: UkSMCRToAdd = {
        ...this.form.value,
        corporateTitle: this.user.title,
        employee: data.name,
        declarationDate: data.declarationDate,
        userEmail: this.user.email
      };

      this.form.reset();
      this.form.disable();

      this.service.acknowledge$(value).subscribe(() => this.router.navigate(['/']));
    });
  }

  private saveFormOnBehalfOf(): void {
    const value: UkSMCRToAdd = {
      ...this.form.value,
      corporateTitle: this.user.title,
      employee: this.user.name,
      declarationDate: this.declarationDate,
      userEmail: this.user.email
    };

    this.form.reset();
    this.form.disable();

    this.service.acknowledge$(value).subscribe(() => this.router.navigate(['/']));
  }

  private populateInitialData(data: UkSMCR): void {
    if (data) {
      this.form.get('irn').setValue(data.irn);
      this.form.get('s2Q1').setValue(data.s2Q1);
      this.form.get('s2Q1HeldFunctions').setValue(data.s2Q1HeldFunctions);
      this.form.get('s2Q1PrescribedResponsibilities').setValue(data.s2Q1PrescribedResponsibilities);
      this.form.get('s2Q2').setValue(data.s2Q2);
      this.form.get('s2Q2HeldCertificationFunctions').setValue(data.s2Q2HeldCertificationFunctions);
      this.form.get('s2Q3a').setValue(data.s2Q3a);
      this.form.get('s2Q3b').setValue(data.s2Q3b);
      this.form.get('s2Q4').setValue(data.s2Q4);
      this.form.get('s2Q5').setValue(data.s2Q5);
      this.form.get('s2Q6').setValue(data.s2Q6);
      this.form.get('s2Q7a').setValue(data.s2Q7a);
      this.form.get('s2Q7b').setValue(data.s2Q7b);
      this.form.get('s2Q8a').setValue(data.s2Q8a);
      this.form.get('s2Q8b').setValue(data.s2Q8b);

      if (!this.hasLastYearData) {
        this.declarationDate = data.declarationDate;
        this.user = {
          name: data.employee,
          email: data.userEmail,
          title: data.corporateTitle
        };
      }

      if (data.declarationDate) {
        this.alreadySubmitted = true;
        this.form.disable();
      }
    }
  }
}
